import React from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CustomNavBar from "../CustomNavBar";

const OrderPlacedFailed = () => {
  const navigate = useNavigate();

  return (
    <>
      <CustomNavBar title={"Order Status"} to={"/myorders"} />
      <section>
        <div className="desktop-wrapper mt-5">
          <Container className="cart-wrapper">
            <div className="details-card text-center">
              <img
                src="../../img/incorrect.png"
                className="success-img"
                alt="successimg"
              />
              <h3 className="red-text">Your Order has not been placed!</h3>
              {/* <p>Your phone number has been verified successfully.</p> */}
              <Button
                variant="primary"
                size="lg"
                className="mt-3"
                onClick={() => {
                  navigate("/myorders");
                }}
              >
                My Orders
              </Button>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default OrderPlacedFailed;
