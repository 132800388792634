import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import APIService from "../../services/api.service";
import CustomBrandCard from "./CustomBrandCard";
import { useSelector } from "react-redux";

const TopBrands = () => {
  const { offers } = useSelector((state) => state.offer);
  // console.log("offers", offers);

  const [brands, setBrands] = useState([]);

  useEffect(() => {
    APIService.sendRequest("/api/v1/products/brands/top").then((response) => {
      setBrands(response?.brands);
      // console.log(response);
    });
  }, []);
  return (
    <section className="scroll-hidden mb-5">
      <div className="container">
        <h4>
          Top Brands <Link to={"/brandlist?type=top"}>View All</Link>{" "}
        </h4>
        <div className="horizontal-scroll d-flex card-scroll">
          {brands &&
            brands.map((brand, i) => {
              // console.log(brand);
              // TODO: remove this logic
              var offs = offers.filter(
                (offer) => brand.brandName === offer.productId.brandName
              );
              var dis = 0;
              if (offs.length > 0) dis = offs[0].discount;
              // console.log("offs", offs);
              return (
                <CustomBrandCard
                  key={i}
                  image={
                    brand?.images?.custom ||
                    brand?.images?.mobile ||
                    brand?.images?.small ||
                    brand?.images?.base
                  }
                  name={brand?.name || "randomg"}
                  discount={dis}
                />
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default TopBrands;
