import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { MdArrowBack } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

import { getUser } from "../../store/userSlice";

const MobileVerified = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUser());
  }, []);

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container>
          <div>
            <Link to="/profiledetails" className="back-icon">
              <MdArrowBack />
            </Link>
            <h5 className="text-white m-3 float-start">Phone Verification</h5>
          </div>
        </Container>
      </Navbar>

      <section>
        <div className="desktop-wrapper mt-5">
          <Container className="cart-wrapper">
            <div className="details-card text-center">
              <img
                src="../../img/success.svg"
                className="success-img"
                alt="successimg"
              />
              <h3 className="green-text">Phone Verified!</h3>
              <p>Your phone number has been verified successfully.</p>
              <Button
                variant="primary"
                size="lg"
                className="mt-3"
                onClick={() => {
                  navigate("/");
                }}
              >
                Home
              </Button>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default MobileVerified;
