import React from "react";

const SBIPaymentGateway = (props) => {
  React.useEffect(() => {
    window.location.replace(props?.gateway?.link);
  }, []);

  // return <div>SBIPaymentGateway</div>;
};

export default SBIPaymentGateway;
