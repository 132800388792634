import axios from "axios";
import { getCookieItem, removeCookieItem } from "../pages/secureroute/cookies";

const API_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

const _getApiHeaders = () => {
  // TODO: write token expiry, logout logic here
  const token = getCookieItem("token");
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + token,
  };
  return headers;
};

const sendRequest = (
  url,
  data = null,
  method = "GET",
  headers,
  onUploadProgress = null
) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!headers) headers = _getApiHeaders();
      // console.log("headers: ", headers);

      var response;
      if (method === "GET") {
        // console.log(method, API_BASE_URL + url, data, headers);
        response = await axios({
          method: method,
          url: API_BASE_URL + url,
          headers: headers,
        });
        // console.log("resssss", response);
        // console.log("resssss blob", response.blob);
      } else if (method === "POST") {
        // console.log(method, API_BASE_URL + url, data, headers);
        response = await axios({
          method: method,
          url: API_BASE_URL + url,
          data: data,
          headers: headers,

          // onUploadProgress: onUploadProgress,
        });

        // response = await axios.post({
        //   url,
        //   data,
        //   headers,
        //   onUploadProgress,
        // });
      } else if (method === "DELETE") {
        response = await axios({
          method: method,
          url: API_BASE_URL + url,
          data: data,
          headers: headers,
        });
      } else if (method === "PATCH") {
        response = await axios({
          method: method,
          url: API_BASE_URL + url,
          data: data,
          headers: headers,
        });
      } else response.data = {};
      // console.log("positive");
      // console.log(response);
      resolve(response.data);
    } catch (error) {
      // console.log("error");
      // console.log(error);
      // console.log(error.response);
      if (error?.response?.status === 401) {
        removeCookieItem("token");
        window.location.href = window.location.origin + "/login";
      }
      // TODO: handle 404, 500 too
      resolve(error.response.data);
    }
  });
};

const APIService = {
  sendRequest,
};
export default APIService;
