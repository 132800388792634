import { createSlice } from "@reduxjs/toolkit";
import {
  getCookieItem,
  removeCookieItem,
  setCookieItem,
} from "../pages/secureroute/cookies";

import APIService from "../services/api.service";

// console.log(getCookieItem("user"), typeof getCookieItem("user"));

const initialState = {
  user:
    getCookieItem("user") && getCookieItem("user") !== "undefined"
      ? JSON.parse(getCookieItem("user"))
      : {} || {},
  isLoggedIn: getCookieItem("token") ? true : false,
  token: getCookieItem("token") || null,
  success: false,
  errorMessage: "",
  sentForgotPasswordEmail: false,
  isPasswordChanged: false,
  isLoginError: false,
  loginErrorMessage: "",
  isSignUpSuccess: false,
  isSignupError: false,
  signUpErrorMessage: "",
  isUserUpdateSuccess: false,
  isUserUpdateError: false,
  userUpdateErrorMessage: "",
  addresses: [],
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload?.user;
      state.isLoggedIn = true;
      state.success = action.payload?.success;
    },
    setLogInUser(state, action) {
      state.user = action.payload?.user;
      state.token = action.payload?.token;
      state.isLoggedIn = true;
      state.success = action.payload?.success;
      state.errorMessage = action.payload?.errorMessage;

      setCookieItem("token", action.payload?.token);
      setCookieItem("user", JSON.stringify(action.payload?.user));
      // console.log(getCookieItem("token"));
      // console.log(getCookieItem("user"));
    },
    setSentForgotPasswordEmail(state, action) {
      state.sentForgotPasswordEmail = action.payload?.sentForgotPasswordEmail;
    },
    setIsPasswordChanged(state, action) {
      state.isPasswordChanged = action.payload?.isPasswordChanged;
    },
    setAddresses(state, action) {
      state.addresses = action.payload?.addresses;
    },
    setError(state, action) {
      state.user = {};
      state.token = null;
      state.isLoggedIn = false;
      state.success = true;
      state.errorMessage = action.payload?.errorMessage;
    },
    setLoginError(state, action) {
      state.user = {};
      state.token = null;
      state.isLoggedIn = false;
      state.isLoginError = !action.payload?.success;
      state.loginErrorMessage = action.payload?.errorMessage;
    },
    setUserUpdateError(state, action) {
      state.isUserUpdateError = !action.payload?.success;
      state.userUpdateErrorMessage = action.payload?.errorMessage;
    },
    setUserUpdateSuccess(state, action) {
      state.isUserUpdateSuccess = action.payload?.success;
    },
    setSignUpError(state, action) {
      state.user = {};
      state.token = null;
      state.isLoggedIn = false;
      state.isSignupError = !action.payload?.success;
      state.signUpErrorMessage = action.payload?.errorMessage;
    },
    setSignUpSuccess(state, action) {
      state.user = {};
      state.token = null;
      state.isLoggedIn = false;
      state.isSignUpSuccess = action.payload?.success;
    },
    login(state, action) {
      // console.log(state.payload);
    },
    logout(state) {
      removeCookieItem("user");
      removeCookieItem("token");
      state.isLoggedIn = false;
      state.token = null;
    },
    setForgotPwdEmailStatus(state, action) {
      state.sentForgotPasswordEmail = true;
    },
  },
});

export const loginUser = (user) => {
  return async (dispatch) => {
    try {
      const response = await APIService.sendRequest(
        "/auth/login",
        user,
        "POST",
        {
          "Content-Type": "application/json",
          Accept: "application/json",
        }
      );
      // console.log(response);
      if (response?.success) dispatch(userActions.setLogInUser(response));
      if (!response?.success) dispatch(userActions.setLoginError(response));
    } catch (err) {
      // console.log(err);
    }
  };
};

export const signUpUser = (user) => {
  return async (dispatch) => {
    try {
      const response = await APIService.sendRequest(
        "/auth/signup",
        user,
        "POST",
        {
          "Content-Type": "application/json",
          Accept: "application/json",
        }
      );
      // console.log(response);
      if (response?.success) dispatch(userActions.setSignUpSuccess(response));
      if (!response?.success) dispatch(userActions.setSignUpError(response));
    } catch (err) {
      // console.log(err);
    }
  };
};

export const logoutUser = (user) => {
  return async (dispatch) => {
    try {
      dispatch(userActions.logout());
    } catch (err) {
      // console.log(err);
    }
  };
};

export const sendForgotPasswordEmail = (email) => {
  return async (dispatch) => {
    try {
      const response = await APIService.sendRequest(
        "/auth/send/forgotPasswordLink/" + email,
        {
          "Content-Type": "application/json",
          Accept: "application/json",
        }
      );
      // console.log(response);
      if (response?.success)
        dispatch(userActions.setForgotPwdEmailStatus(response));
      // if (response?.success) dispatch(userActions.setError(response));
    } catch (err) {
      // console.log(err);
    }
  };
};

export const changePassword = (data) => {
  return async (dispatch) => {
    try {
      const response = await APIService.sendRequest(
        "/auth/password/change",
        data,
        "POST"
      );
      // console.log(response);
      if (response?.success)
        dispatch(userActions.setIsPasswordChanged({ isPasswordChanged: true }));
      // if (response?.success) dispatch(userActions.setError(response));
    } catch (err) {
      // console.log(err);
    }
  };
};

export const getAddresses = () => {
  return async (dispatch) => {
    try {
      const response = await APIService.sendRequest("/api/v1/address");
      // console.log(response);
      if (response?.success) dispatch(userActions.setAddresses(response));
      // if (response?.success) dispatch(userActions.setError(response));
    } catch (err) {
      // console.log(err);
    }
  };
};

export const updateUser = (user) => {
  return async (dispatch) => {
    try {
      const response = await APIService.sendRequest("/auth", user, "PATCH");
      // console.log(response);
      if (response?.success) {
        dispatch(getUser());
        dispatch(userActions.setUserUpdateSuccess(response));
      }
      if (!response?.success) {
        dispatch(userActions.setUserUpdateError(response));
      }
    } catch (err) {
      // console.log(err);
    }
  };
};

export const getUser = () => {
  return async (dispatch) => {
    try {
      const response = await APIService.sendRequest("/auth");
      // console.log("response", response);
      if (response?.success) {
        dispatch(userActions.setUser(response));
      }
      // if (!response?.success) {
      //   dispatch(userActions.setLoginError(response));
      // }
    } catch (err) {
      // console.log(err);
    }
  };
};

export const userActions = userSlice.actions;
export default userSlice.reducer;
