import React, { useEffect, useState } from "react";
import APIService from "../../services/api.service";

import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const PayuPaymentGateway = (props) => {
  const navigate = useNavigate();
  const [gateway, setGateway] = useState({ ...props.gateway });
  // console.log("gateway......", gateway);

  var responseCallBack = function (response) {
    // payment successful
    if ("generateHash" in response) {
      // console.log("generateHash in gateway...");
      // console.log(JSON.stringify(response));
      generateHash(response);
    } else if ("onPaymentSuccess" in response) {
      onPaymentSuccess(response);
    } else if ("onPaymentFailure" in response) {
      onPaymentFailure(response);
    } else if ("onPaymentCancel" in response) {
      onPaymentCancel(response);
    } else if ("onError" in response) {
      onError(response);
    }
  };

  //Handle Callback methods from SDK
  async function generateHash(response) {
    // console.log("before generateHash", JSON.stringify(response));
    var resultValue = response.generateHash;
    var hashName = resultValue.hashName;
    var hash = {};
    // hash[hashName] = await sha512(hashStringWithoutSalt + merchantSalt);

    APIService.sendRequest(
      "/api/v1/order/payu/inPlaceHashes",
      { ...resultValue },
      "POST"
    ).then((response) => {
      // console.log("hash from server", JSON.stringify(response));
      if (response?.success) {
        hash[hashName] = response?.hash;
        window.cordova.plugins.PayUCheckoutProCordova.hashGenerated(hash);
      } else {
        showNotifications(response?.errorMessage);
      }
    });
  }

  function onPaymentSuccess(response) {
    //Handle on Payment Success Response
    // {"onPaymentSuccess":"{payuResponse={\"id\":17046768415,\"mode\":\"CASH\",\"status\":\"success\",\"unmappedstatus\":\"captured\",\"key\":\"1ZoPkv\",\"txnid\":\"ZP0354-353003-6445\",\"transaction_fee\":\"10.00\",\"amount\":\"10.00\",\"discount\":\"0.00\",\"addedon\":\"2023-03-24 22:12:19\",\"productinfo\":\"giftcard\",\"firstname\":\"Namdev Gavle\",\"email\":\"gavlenamdevprabha@gmail.com\",\"phone\":\"8074123870\",\"hash\":\"c34332ca52e4720fde4d9af0e8b8c2d8c67f2856829c1fcd7faf11cce8a80f989e26df185912fcee6c68d34924dbbbe3ac2511c337d4147e00db9e0e5d3b04d7\",\"field6\":\"PAYMENT_SUCCESS\",\"field9\":\"Payment is successful\",\"payment_source\":\"payu\",\"PG_TYPE\":\"CASH-PG\",\"bank_ref_no\":\"T2303242213042737110112\",\"ibibo_code\":\"PHONEPE\",\"error_code\":\"E000\",\"Error_Message\":\"No Error\",\"is_seamless\":1,\"surl\":\"https://b.zooppay.com/api/v1/order/payu/success\",\"furl\":\"https://b.zooppay.com/api/v1/order/payu/failure\"}, merchantResponse=null}"}

    // console.log("onPaymentSuccess", response);
    // console.log(JSON.stringify(response));
    // console.log(
    //   JSON.stringify(response?.onPaymentSuccess),
    //   typeof response?.onPaymentSuccess
    // );
    navigate("/order/success");
  }
  function onPaymentFailure(response) {
    //Handle on Payment Failure Response
    // console.log("onPaymentFailure", response);
    // console.log(JSON.stringify(response));
    // console.log(JSON.stringify(response?.onPaymentFailure));
    navigate("/order/failure");
  }

  function onPaymentCancel(response) {
    //Handle on Payment Cancel Response
    // console.log("onPaymentCancel", response);
    // console.log(JSON.stringify(response));
    // console.log(JSON.stringify(response?.onPaymentCancel));
    navigate("/order/cancel");
  }

  function onError(response) {
    //Handle on Error Response
    // console.log("onError", response);
    // console.log(JSON.stringify(response));
    // console.log(JSON.stringify(response?.onError));
    navigate("/order/error");
  }

  var payUPaymentParams = {
    key: gateway.key,
    transactionId: gateway.txnid,
    amount: gateway.amount,
    productInfo: gateway.productinfo,
    firstName: gateway.firstname,
    email: gateway.email,
    phone: gateway.phone,
    ios_surl: gateway.surl,
    ios_furl: gateway.furl,
    android_surl: gateway.surl,
    android_furl: gateway.furl,
    environment: "0",
  };

  var payUCheckoutProConfig = {
    primaryColor: "#3b39c4",
    secondaryColor: "#60c43b",
    merchantSMSPermission: false,
    autoSelectOtp: false,
    // merchantResponseTimeout: 25000,
  };

  // var additionalParam =  {
  //   udf1: "user defined value 1",
  //   udf2: "user defined value 2",
  //   udf3: "user defined value 3",
  //   udf4: "user defined value 4",
  //   udf5: "user defined value 5",
  //   merchantAccessKey:"", //This is for lookup API, optional
  //   sourceId:"", //Sodexo source ID, optional
  // }

  useEffect(() => {
    if (
      gateway.key &&
      gateway.txnid &&
      gateway.amount &&
      gateway.productinfo &&
      gateway.firstname &&
      gateway.email &&
      gateway.phone &&
      gateway.surl &&
      gateway.furl &&
      gateway.hash &&
      gateway.action
    ) {
      // document.forms["payment_post"].submit();
      let payuParams = {
        payUPaymentParams: payUPaymentParams,
        payUCheckoutProConfig: payUCheckoutProConfig,
      };
      window.cordova.plugins.PayUCheckoutProCordova.openCheckoutScreen(
        responseCallBack,
        payuParams
      );
    }
  }, [gateway]);

  const showNotifications = (msg) => {
    toast(msg, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      // className: "toaster_custom_color",
      // bodyClassName: "toaster_custom_body_color",
    });
  };

  return (
    <>
      <form
        name="payment_post"
        id="payment_post"
        action={gateway.action}
        method="post"
      >
        <input hidden type="text" name="key" defaultValue={gateway.key} />
        <input hidden type="text" name="txnid" defaultValue={gateway.txnid} />
        <input hidden type="text" name="amount" defaultValue={gateway.amount} />
        <input
          hidden
          type="text"
          name="productinfo"
          defaultValue={gateway.productinfo}
        />
        <input
          hidden
          type="text"
          name="firstname"
          defaultValue={gateway.firstname}
        />
        <input hidden type="text" name="email" defaultValue={gateway.email} />
        <input hidden type="text" name="phone" defaultValue={gateway.phone} />
        <input hidden type="text" name="surl" defaultValue={gateway.surl} />
        <input hidden type="text" name="furl" defaultValue={gateway.furl} />
        <input hidden type="text" name="curl" defaultValue={gateway.curl} />
        <input hidden type="text" name="hash" defaultValue={gateway.hash} />
      </form>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default PayuPaymentGateway;

// The specified message queue synchronization  barrier token has not been posted or has already been removed
//         2023-03-24 01:18:28.576 30531-30531 Capacitor/Console       com.zooppay.zooppay                  I  File: http://localhost/static/js/main.32c16aa0.js - Line 2 - Msg: generateHash in gateway...
//         2023-03-24 01:18:28.576 30531-30531 Capacitor/Console       com.zooppay.zooppay                  I  File: http://localhost/static/js/main.32c16aa0.js - Line 2 - Msg: {"generateHash":{"hashString":"1ZoPkv|get_sdk_configuration|GET|","hashName":"get_sdk_configuration"}}
//         2023-03-24 01:18:28.576 30531-30531 Capacitor/Console       com.zooppay.zooppay                  I  File: http://localhost/static/js/main.32c16aa0.js - Line 2 - Msg: before generateHash {"generateHash":{"hashString":"1ZoPkv|get_sdk_configuration|GET|","hashName":"get_sdk_configuration"}}
//         2023-03-24 01:18:28.577 30531-30531 Capacitor/Plugin        com.zooppay.zooppay                  V  To native (Cordova plugin): callbackId: INVALID, service: PayUCheckoutProCordova, action: hashGenerated, actionArgs: [{"get_sdk_configuration":{"$super":{"$super":{}},"words":[1609641674,4378331534,1693003725,308063214,892402929,3860587780,4203985087,1014439318,-91294968,2081579891,4249047127,-27741887,1973666577,3605690100,-360690233,707586626],"sigBytes":64}}]
//         2023-03-24 01:18:28.577 30531-30531 Capacitor/Console       com.zooppay.zooppay                  I  File: http://localhost/static/js/main.32c16aa0.js - Line 2 - Msg: after generateHash {"generateHash":{"hashString":"1ZoPkv|get_sdk_configuration|GET|","hashName":"get_sdk_configuration"}}
//         2023-03-24 01:18:28.572 30531-30531 ViewRootImpl            com.zooppay.zooppay                  D  support adaptive color gamut feature!
//         2023-03-24 01:18:28.587  1594-4713  WindowManager           pid-1594                             V  Orientation start waiting for draw, mDrawState=DRAW_PENDING in Window{152871c u0 com.zooppay.zooppay/com.payu.ui.view.activities.CheckoutActivity}, surfaceController Surface(name=com.zooppay.zooppay/com.payu.ui.view.activities.CheckoutActivity)/@0x8ef908
//         2023-03-24 01:18:28.652 30531-30531 Choreographer           com.zooppay.zooppay                  W  Already have a pending vsync event.  There should only be one at a time.
//         2023-03-24 01:18:28.652 30531-30531 Capacitor/Console       com.zooppay.zooppay                  I  File: http://localhost/static/js/main.32c16aa0.js - Line 2 - Msg: generateHash in gateway...
//         2023-03-24 01:18:28.652 30531-30531 Capacitor/Console       com.zooppay.zooppay                  I  File: http://localhost/static/js/main.32c16aa0.js - Line 2 - Msg: {"generateHash":{"hashString":"1ZoPkv|payment_related_details_for_mobile_sdk|default|","hashName":"payment_related_details_for_mobile_sdk"}}
//         2023-03-24 01:18:28.652 30531-30531 Capacitor/Console       com.zooppay.zooppay                  I  File: http://localhost/static/js/main.32c16aa0.js - Line 2 - Msg: before generateHash {"generateHash":{"hashString":"1ZoPkv|payment_related_details_for_mobile_sdk|default|","hashName":"payment_related_details_for_mobile_sdk"}}
//         2023-03-24 01:18:28.653 30531-30531 Capacitor/Plugin        com.zooppay.zooppay                  V  To native (Cordova plugin): callbackId: INVALID, service: PayUCheckoutProCordova, action: hashGenerated, actionArgs: [{"payment_related_details_for_mobile_sdk":{"$super":{"$super":{}},"words":[-9260985,2838488917,1717982612,3789714285,878735513,5979737976,3576027343,3147205215,1464052258,1828613892,2066908660,1837078095,2397411062,5194809365,-320938423,1595056727],"sigBytes":64}}]
//         2023-03-24 01:18:28.654 30531-30531 Capacitor/Console       com.zooppay.zooppay                  I  File: http://localhost/static/js/main.32c16aa0.js - Line 2 - Msg: after generateHash {"generateHash":{"hashString":"1ZoPkv|payment_related_details_for_mobile_sdk|default|","hashName":"payment_related_details_for_mobile_sdk"}}
//         2023-03-24 01:18:28.654 30531-30531 ViewRootIm...tActivity] com.zooppay.zooppay                  D  windowFocusChanged hasFocus=false inTouchMode=true
