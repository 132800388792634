import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { MdArrowBack, MdCheckCircle } from "react-icons/md";
import { Link } from "react-router-dom";

import { userActions } from "../../store/userSlice";
import { Button } from "react-bootstrap";

const ChangePasswordSuccess = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.setIsPasswordChanged({ isPasswordChanged: false }));
  }, []);

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container>
          <div>
            <Link to="/" className="back-icon">
              <MdArrowBack />
            </Link>
            <h5 className="text-white m-3 float-start">Change Password</h5>
          </div>
        </Container>
      </Navbar>

      <section>
        <div className="desktop-wrapper mt-5">
          <Container className="cart-wrapper">
            <div className="details-card text-center">
              <img
                src="../../img/success.svg"
                className="success-img"
                alt="success-img"
              />
              <h3 className="green-text">Password Changed!</h3>
              <p>Your password has been changed successfully.</p>
              <Link to={"/"}>
                <Button variant="primary" size="lg" className="mt-3">
                  Go Home
                </Button>
              </Link>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default ChangePasswordSuccess;
