import React, { useEffect, useState } from "react";

import { Button, Form, Tab, Tabs } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { MdStar } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import APIService from "../../services/api.service";
import { getCartProducts } from "../../store/cartSlice";
import CustomNavBar from "../CustomNavBar";
import GiftCardOffer from "./GiftCardOffer";

function GiftCardDetails() {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.cart);
  const { isLoggedIn } = useSelector((state) => state.user);

  const params = useParams();

  const cartItem = products.find((p) => p.productId === params.productId);

  // console.log("nnn", cartItem);
  // console.log(params);
  const [product, setProduct] = useState({});
  const [offer, setOffer] = useState({});
  const [amount, setAmount] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // TODO: retain this flag even after coming back to this page
  // use cartSlice
  const [isAddedToCard, setIsAddedToCart] = useState(false);

  const handleAmountChange = (e) => {
    // console.log(e.target.value);

    // console.log("amount", e.target.value);
    if (/^\d*\.?\d*$/.test(e.target.value)) {
      // console.log("number", e.target.value);
      if (e.target.value !== "") {
        setAmount(parseInt(e.target.value));
      }
      if (e.target.value === "") {
        setAmount(e.target.value);
      }
    }
  };

  useEffect(() => {
    if (params?.productId)
      APIService.sendRequest("/api/v1/products/" + params?.productId).then(
        (response) => {
          setProduct(response?.product);
          setOffer(response?.offer);
          // console.log(response);
        }
      );
  }, [params?.productId]);

  useEffect(() => {
    if (isLoggedIn) dispatch(getCartProducts());
  }, [dispatch, isLoggedIn]);

  const isMultiple = (num, arr) => {
    for (var a of arr) {
      if (num % parseInt(a) === 0) return true;
    }
    return false;
  };

  const addPreDefinedAmountToCart = (amt) => {
    APIService.sendRequest(
      "/api/v1/cart",
      { productId: params.productId, quantity: 1, price: amt },
      "POST"
    ).then((response) => {
      if (response?.success) {
        setIsAddedToCart(true);
        dispatch(getCartProducts());
        showNotifications("Item added to cart");
      } else {
        setIsAddedToCart(false);
        setIsError(true);
        setErrorMessage(response.errorMessage);
        showNotifications(response.errorMessage);
      }
    });
  };

  const handleAddProductToCart = async () => {
    // console.log(productId);
    if (!params.productId) {
      // console.log("Product ID not found");
      setIsError(true);
      setErrorMessage("Product ID not found");
      showNotifications("Product ID not found");
      return;
    }
    if (!amount) {
      // console.log("Please enter amount");
      setIsError(true);
      setErrorMessage("Please enter amount");
      showNotifications("Please enter amount");
      return;
    }
    if (amount < parseInt(product?.issuerData?.price.min)) {
      // console.log("minimum price should be " + product?.issuerData?.price?.min);
      setIsError(true);
      setErrorMessage(
        "minimum price should be " + product?.issuerData?.price?.min
      );
      showNotifications(
        "minimum price should be " + product?.issuerData?.price?.min
      );
      return;
    }
    // console.log(
    //   typeof amount,
    //   typeof product?.issuerData?.price.max,
    //   amount > product?.issuerData?.price.max
    // );
    if (amount > parseInt(product?.issuerData?.price.max)) {
      // console.log(
      //   "price should not be exceed " + product?.issuerData?.price.max
      // );
      setIsError(true);
      setErrorMessage(
        "price should not be exceed " + product?.issuerData?.price?.max
      );
      showNotifications(
        "price should not be exceed " + product?.issuerData?.price?.max
      );
      return;
    }
    // console.log("denominations");
    // console.log(product?.issuerData?.price.denominations);
    // console.log(product?.issuerData?.price.denominations.length);

    if (
      product?.issuerData?.price.denominations &&
      product?.issuerData?.price.denominations.length > 0 &&
      !isMultiple(amount, product?.issuerData?.price.denominations)
    ) {
      // console.log(
      //   "price should follow these denominations " +
      //     product?.issuerData?.price.denominations.join(", ")
      // );
      setIsError(true);
      setErrorMessage(
        "price should follow these denominations " +
          product?.issuerData?.price.denominations.join(", ")
      );
      showNotifications(
        "price should follow these denominations " +
          product?.issuerData?.price.denominations.join(", ")
      );
      return;
    }

    setIsError(false);

    // login required
    // const isAuthenticated = JSON.parse(localStorage.getItem("user"));
    // if (!isAuthenticated) return navigator("/login");

    // const response = await CartService.addToCart(productId, 1, amount);
    // console.log("response");
    // console.log(response);
    // console.log(response.message);
    // send _id to cart api and notify
    // already added to cart message

    APIService.sendRequest(
      "/api/v1/cart",
      { productId: params.productId, quantity: 1, price: amount },
      "POST"
    ).then((response) => {
      if (response?.success) {
        setIsAddedToCart(true);
        dispatch(getCartProducts());
        showNotifications("Item added to cart");
      } else {
        setIsAddedToCart(false);
        setIsError(true);
        setErrorMessage(response.errorMessage);
        showNotifications(response.errorMessage);
      }
    });
  };

  const showNotifications = (msg) => {
    toast(msg, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      // className: "toaster_custom_color",
      // bodyClassName: "toaster_custom_body_color",
    });
  };

  return (
    <>
      <CustomNavBar title="Giftcards Details" />
      <section>
        <div className="gift-details">
          <Container className="">
            <div className="details-card">
              <div className="d-flex details-first">
                <div className="w-40 text-start">
                  <img
                    className="mb-2 w-100"
                    src={product?.images?.custom || product?.images?.mobile}
                    alt="thumbnail"
                  />
                  <br />
                </div>
                <div className="w-75 details-text">
                  <h3 className="">
                    <b>{product?.name}</b>
                  </h3>

                  <MdStar />
                  <MdStar />
                  <MdStar />
                  <MdStar />

                  {offer && (
                    <h5 className="tags discount">{offer.discount}% OFF</h5>
                  )}
                  <h5 className="tags available">In Stock</h5>
                  <p>
                    In case the value of the merchandise exceeds the value of
                    the Gift card, the difference shall be paid by the customer.
                  </p>
                  <p>
                    <small>From </small>
                    <br />
                    <b>₹{product?.price?.min}</b>
                  </p>
                  <div className="add-help">
                    {product?.price?.denominations?.map((value, i) => (
                      <span
                        key={i}
                        onClick={() => {
                          addPreDefinedAmountToCart(value);
                        }}
                        className={
                          cartItem?.price === value
                            ? "added-to-cart-button"
                            : ""
                        }
                      >
                        {" "}
                        ₹{value} <AiOutlineShoppingCart />
                        {/* <BsFillCartCheckFill /> */}
                      </span>
                    ))}
                  </div>
                  {/* <div className="add-help">
                    <span> ₹100</span>
                    <span> ₹300</span>
                    <span> ₹500</span>
                    <span> ₹1,000</span>
                    <span> ₹2,000</span>
                  </div> */}
                  <div className="footer-action">
                    <InputGroup>
                      <InputGroup.Text id="basic-addon3">₹</InputGroup.Text>
                      <Form.Control
                        id="basic-url"
                        placeholder="Enter Amount"
                        aria-describedby="basic-addon3"
                        onChange={handleAmountChange}
                        defaultValue={amount}
                        isInvalid={isError}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorMessage}
                      </Form.Control.Feedback>
                    </InputGroup>

                    <Button
                      variant="primary add-cart"
                      size="md"
                      onClick={handleAddProductToCart}
                    >
                      {isAddedToCard ? "Added To Cart" : "Add To Cart"}
                    </Button>
                  </div>
                </div>
              </div>

              <div className="details-first">
                <Tabs
                  defaultActiveKey="home"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="home" title="Description">
                    <p>{product?.description?.content}</p>
                  </Tab>
                  {offer && (
                    <Tab eventKey="profile" title="Offer Details">
                      <GiftCardOffer offer={offer} />
                    </Tab>
                  )}
                </Tabs>
              </div>
            </div>
          </Container>
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </section>
    </>
  );
}

export default GiftCardDetails;
