import Container from "react-bootstrap/Container";
import CustomNavBar from "./CustomNavBar";

function About() {
  return (
    <>
      <CustomNavBar title={"How It Works?"} />
      <section className="profile-summary-wrapper">
        <div className="desktop-wrapper">
          <Container className="cart-wrapper">
            <h4>Step 1</h4>
            <p>
              The Gift Card you purchase is redeemable for goods or services by
              the seller of such goods and services or the Service Provider. The
              Service Provider, not Sevenpay, is the seller of the goods and
              services and is solely responsible for redeeming any Gift Card you
              purchase. The Gift Card shall have the necessary redemption
              instructions on it. The Gift Card may have an offer period or
              expiry date mentioned on it. You shall use it accordingly.
            </p>

            <h4>Step 2</h4>
            <p>
              The Gift Card you purchase is redeemable for goods or services by
              the seller of such goods and services or the Service Provider. The
              Service Provider, not Sevenpay, is the seller of the goods and
              services and is solely responsible for redeeming any Gift Card you
              purchase. The Gift Card shall have the necessary redemption
              instructions on it. The Gift Card may have an offer period or
              expiry date mentioned on it. You shall use it accordingly.
            </p>

            <h4>Step 3</h4>
            <p>
              The Gift Card you purchase is redeemable for goods or services by
              the seller of such goods and services or the Service Provider. The
              Service Provider, not Sevenpay, is the seller of the goods and
              services and is solely responsible for redeeming any Gift Card you
              purchase. The Gift Card shall have the necessary redemption
              instructions on it. The Gift Card may have an offer period or
              expiry date mentioned on it. You shall use it accordingly.
            </p>

            <h4>Step 4</h4>
            <p>
              The Gift Card you purchase is redeemable for goods or services by
              the seller of such goods and services or the Service Provider. The
              Service Provider, not Sevenpay, is the seller of the goods and
              services and is solely responsible for redeeming any Gift Card you
              purchase. The Gift Card shall have the necessary redemption
              instructions on it. The Gift Card may have an offer period or
              expiry date mentioned on it. You shall use it accordingly.
            </p>
          </Container>
        </div>
      </section>
    </>
  );
}

export default About;
