import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
import CustomNavBar from "./CustomNavBar";

function TermsAndConditions() {
  return (
    <>
      <Helmet>
        <title>Terms and Conditions | Sevenpay</title>
      </Helmet>
      <CustomNavBar title={"Terms and Conditions"} />
      <section className="profile-summary-wrapper">
        <div className="desktop-wrapper">
          <Container className="cart-wrapper">
            <p>
              This 'User Agreement', which is intended to be a legally binding
              contract between you and us, contains the following: <br />
              <b>Terms of Sale:</b> this governs your purchase and use of the
              Gift Cards;
              <br />
              <b>Website Terms of Use:</b> this governs the access and use of
              the Website; and
              <br />
              <b>Privacy Policy:</b> this governs the use of your personal
              information we collect.
              <br />
              <br />
              You agree that you are of legal age to enter into binding
              contracts, have read, understood and are bound by the User
              Agreement. If you do not want to be bound by this User Agreement,
              you should not use this Website or conduct any sale/purchase
              transaction. References to "you", "User" shall mean the end user
              accessing the Website, its contents and using the Functionalities
              offered through the Website, "Service Providers" mean independent
              third party service providers or merchants, and "we", "us",
              "Sevenpay" and "our" shall mean Sevenpay. We as a merchant shall be
              under no liability whatsoever in respect of any loss or damage
              arising directly or indirectly out of the decline of authorization
              for any Transaction, on Account of the Cardholder having exceeded
              the preset limit mutually agreed by us with our acquiring bank
              from time to time
            </p>

            <h4>I. TERMS OF SALE</h4>
            <p>
              The Gift Card you purchase is redeemable for goods or services by
              the seller of such goods and services or the Service Provider. The
              Service Provider, not Sevenpay, is the seller of the goods and
              services and is solely responsible for redeeming any Gift Card you
              purchase. The Gift Card shall have the necessary redemption
              instructions on it. The Gift Card may have an offer period or
              expiry date mentioned on it. You shall use it accordingly. Unless
              otherwise stated, you will not be entitled to receive credit or
              cash-back for whatever value of the Gift Card you don't use.
              Unless otherwise stated, you cannot combine the Gift Card offer
              with other special offers or promotions that may be on. Unless
              there is something wrong with the purchase or an error
              attributable to us, we do not offer refunds. Gift Card once sold
              are non-refundable, whether you use it or not. Neither Sevenpay nor
              the Service Provider shall have any liability in the event you
              lose your Gift Card at the Service Provider venue. Sevenpay is
              merely the aggregator of deals and issuer of usable Gift Card. The
              Gift Card and the deals are the property of the respective
              third-party Service Provider. As such, Sevenpay shall not be
              responsible for any and all injuries, illnesses, damages, claims,
              liabilities, and costs suffered by or in respect of a customer,
              caused in whole or in parts. Notwithstanding anything to contrary,
              Sevenpay 's entire liability to you shall be the refund of the
              money charged from you for any specific Gift Card, under which the
              unlikely liability arises. Under no circumstance shall Sevenpay be
              liable for any consequential, indirect or remote loss that you or
              your friends and family may suffer.
            </p>

            <h4>II. WEBSITE TERMS OF USE</h4>
            <p>
              A. General www.sevenpay.in ("Website") is an Internet based portal
              owned and operated by Sevenpay, a company incorporated under the
              laws of India, having its registered office at Mumbai, India. Use
              of the Website is offered to you conditioned on acceptance of all
              the terms, conditions and notices contained in these Terms, along
              with any amendments made by Sevenpay at its sole discretion and
              posted on the Website. B. User Account, Password, and Security The
              Website requires you to register as a User by creating an Account
              in order to purchase Gift Card(s) from the Website. You will
              receive a password and account designation upon completing the
              Website's registration process. You will be responsible for
              maintaining the confidentiality of the password and the account
              and are fully responsible for all activities that occur under your
              password or account. You agree to (a) immediately notify Sevenpay
              of any unauthorized use of your password or account or any other
              breach of security, and (b) ensure that you exit from your account
              at the end of each session. Sevenpay cannot and will not be liable
              for any loss or damage arising from your failure to comply with
              this Section B. C. Communication Sevenpay may use the information
              submitted by you to send you e-mails and SMS for administrative
              and marketing purposes, through its servers and systems; or
              through third party e-mail Or SMS service providers. User can
              unsubscribe/opt-out from our communications sent on SMS and Email
              anytime: Please email us at support@woohoo.in to unsubscribe from
              SMS or E-mail. Users can also unsubscribe from emails, by clicking
              the "Unsubscribe URL" mentioned in the footer of the emails. D.
              Functionalities Offered This Website is an information gateway to
              the offers that Sevenpay extends to the Users. E. Privacy Policy
              The User hereby consents, expresses and agrees that he has read
              and fully understands the Privacy Policy of Sevenpay in respect of
              the Website. You further consent that the terms and contents of
              such Privacy Policy are acceptable to you. F. User Obligations You
              agree and undertake to use the Website only to post and upload
              material that is proper. By way of example, and not as a
              limitation, you agree and undertake that when using the Website,
              you will not: Defame, abuse, harass, stalk, threaten or otherwise
              violate the legal rights of others; Publish, post, upload,
              distribute or disseminate any inappropriate, profane, defamatory,
              infringing, obscene, indecent or unlawful topic, name, material or
              information through any bookmark, tag or keyword; Upload files
              that contain software or other material protected by intellectual
              property laws unless you own or control the rights thereto or have
              received all necessary consents; Upload or distribute files that
              contain viruses, corrupted files, or any other similar software or
              programs that may damage the operation of the Website or another's
              computer; Conduct or forward surveys, contests, pyramid schemes or
              chain letters; Download any file posted by another user of a
              Service that you know, or reasonably should know, cannot be
              legally distributed in such manner; Falsify or delete any author
              attributions, legal or other proper notices or proprietary
              designations or labels of the origin or source of software or
              other material contained in a file that is uploaded; Violate any
              code of conduct or other guidelines, which may be applicable for
              or to any particular Service; Violate any applicable laws or
              regulations for the time being in force in or outside India;
              Violate any of the terms and conditions of this Agreement or any
              other terms and conditions for the use of the Website contained
              elsewhere herein; Reverse engineer, modify, copy, distribute,
              transmit, display, perform, reproduce, publish, license, create
              derivative works from, transfer, or sell any information or
              software obtained from the Website; and Notwithstanding anything
              to contrary, Sevenpay's entire liability to you shall be the refund
              of the money charged from you for any specific Gift Card, under
              which the unlikely liability arises. Under no circumstance shall
              Sevenpay be liable for any consequential, indirect or remote loss
              that you or your friends and family may suffer. G. Usage Conduct
              You shall solely be responsible for maintaining the necessary
              computer equipments and Internet connections that may be required
              to access, use and transact on the Website; You are also under an
              obligation to use this Website for reasonable and lawful purposes
              only, and shall not indulge in any activity that is not envisaged
              through the Website; and You shall use this Website, and any Gift
              Card purchased through it, for personal, non-commercial use only
              and shall not re-sell the same to any other person. H.
              Intellectual Property Rights Unless otherwise indicated or
              anything contained to the contrary or any proprietary material
              owned by a third party and so expressly mentioned, Sevenpay owns
              all Intellectual Property Rights to and into the trademarks "[•]",
              and the Website, including, without limitation, any and all
              rights, title and interest in and to copyright, related rights,
              patents, utility models, designs, know-how, trade secrets and
              inventions (patent pending), goodwill, source code, meta tags,
              databases, text, content, graphics, icons, and hyperlinks. You
              acknowledge and agree that you shall not use, reproduce or
              distribute any content from the Website belonging to Sevenpay
              without obtaining authorization from Sevenpay. Notwithstanding the
              foregoing, it is expressly clarified that you will solely be
              responsible for any content that you provide or upload when using
              any Service, including any text, data, information, images,
              photographs, music, sound, video or any other material which may
              be accessible through your post, or any other content that you
              upload, transmit or store when using the Website. I. Links To
              Third Party Sites Sevenpay will contain links to other websites ("
              Linked Sites"). The Linked Sites are not under the control of
              Sevenpay, and Sevenpay is not responsible for the contents of any
              Linked Site, including without limitation any link contained in a
              Linked Site, or any changes or updates to a Linked Site. Sevenpay
              is not responsible for any form of transmission, whatsoever,
              received by you from any Linked Site. Sevenpay provides links of
              online advertisements to you only as a convenience and the
              inclusion of any link does not imply endorsement by or affiliation
              with Sevenpay of the Linked Sites nor does it represent the advice,
              views, opinions or beliefs of Sevenpay. The users are requested to
              verify the accuracy of all information on their own before
              undertaking any reliance on such information. In the event that by
              accessing the Website or following links to third-party websites
              you are exposed to content that you consider offensive or
              inappropriate, your only recourse will be to stop using the
              Website. J. Disclaimer of Warranties & Liability Sevenpay has
              endeavored to ensure that all the information on the Website is
              correct, but Sevenpay neither warrants nor makes any
              representations regarding the quality, accuracy or completeness of
              any data, information, product or Service. In no event shall
              Sevenpay be liable for any direct, indirect, punitive, incidental,
              special, consequential damages or any other damages. Neither shall
              Sevenpay be responsible for the delay or inability to use the
              Website or related Functionalities, the provision of or failure to
              provide Functionalities, or for any information, software,
              products, Functionalities and related graphics obtained through
              the Website, or otherwise arising out of the use of the website,
              whether based on contract, tort, negligence, strict liability or
              otherwise. Further, Sevenpay shall not be held responsible for
              non-availability of the Website during periodic maintenance
              operations or any unplanned suspension of access to the website
              that may occur due to technical reasons or for any reason beyond
              Sevenpay' control. The user understands and agrees that any
              material and/or data downloaded or otherwise obtained through the
              Website is done entirely at their own discretion and risk and they
              will be solely responsible for any damage to their computer
              systems or loss of data that results from the download of such
              material and/or data. K. Indemnification You agree to indemnify,
              defend and hold harmless Sevenpay from and against any and all
              losses, liabilities, claims, damages, costs, and expenses
              (including legal fees and disbursements in connection therewith
              and interest chargeable thereon) asserted against or incurred by
              Sevenpay that arise out of, result from, or may be payable by
              virtue of, any breach or non-performance of any representation,
              warranty, covenant or agreement made or obligation to be performed
              by you pursuant to these Terms. L. Termination Sevenpay may suspend
              or terminate your use of the Website or any Service at its sole
              and absolute discretion. Notwithstanding the foregoing sub-section
              above, these Terms will survive indefinitely unless and until
              Sevenpay chooses to terminate them; and If you or Sevenpay
              terminates your use of the Website, Sevenpay may delete any content
              or other materials relating to your use of the Website and Sevenpay
              will have no liability to you or any third party for doing so.
              Offers of discounts and freebies cannot be clubbed with any other
              promotional offers M. Governing Law These terms shall be governed
              by and constructed in accordance with the laws of India without
              reference to conflict of laws principles, and disputes arising in
              relation hereto shall be subject to the exclusive jurisdiction of
              the courts at Mumbai, India. M. Report Abuse If you come across
              any abuse or violation of these Terms, please report to
              support@woohoo.in
            </p>

            <h4>III. PRIVACY POLICY</h4>
            <p>
              A. General Usage This Privacy Policy explains how Sevenpay
              collects, uses, discloses and protects your Personal Information,
              obtained explicitly through www.sevenpay.in website (" Site"), as
              well as other information collected automatically without direct
              submission by you. 'Personal Information' is information that
              enables anyone to contact you and your credit card details. Your
              full name, address, IP address, e-mail address are other examples
              of Personal Information. You may need to provide Sevenpay your
              Personal Information during the course of using some services of
              the Sevenpay; for instance, while registering on the website and
              while making an online purchase. Sevenpay may use the information
              submitted by you to send you e-mails for administrative and
              marketing purposes. Sevenpay does not make these e-mail addresses
              available to any third party. Sevenpay DOES NOT trade or sell your
              Personal Information in any manner, except as is provided under
              Section III (B) below, or if express consent is sought from you.
              When you use the Site, Sevenpay' servers (which may be hosted by a
              third-party service provider) may collect information indirectly
              and automatically about your activities on the Site; for instance
              by way of cookies. A cookie is a small amount of data that is sent
              to a user's browser from a web server and is eventually stored on
              a user's computer hard drive. This anonymous information is
              maintained distinctly and is not linked to the Personal
              Information you submit to Sevenpay. The anonymous information will
              be used for Sevenpay' understanding of how the Site is used and for
              other statistical purposes, for system administration and to
              personalize your experience. Sevenpay may also collect information
              related to access times, browser type, and your IP address for
              tracking usage patterns and, if necessary, deny service in
              accordance with our Terms. Sevenpay may disclose aggregated
              information about the use of the Site, but it will never contain
              Personal Information. Sevenpay does not disclose Personal
              Information to third parties in the normal course of operations.
              However, in situations when Sevenpay is legally obligated to
              disclose information to the government or other third parties,
              Sevenpay will do so. Sevenpay implements standard measures to
              protect against unauthorized access to and unlawful interception
              of Personal Information. However, no internet site can fully
              eliminate security risks. B. Shared Personal Information You are
              required to submit your personal information at the time of making
              an online purchase of Sevenpay Gift Cards. Sevenpay uses an online
              payment gateway that is operated by a third party and the personal
              information that you share with Sevenpay is transferred and shared
              with such third party payment gateway operator. The said operator
              may also have access to your online purchase history/details that
              you make from www.sevenpay.in. You are required to submit your
              personal information at the time of making an online purchase of
              Sevenpay Gift Cards. Sevenpay uses an online payment gateway that is
              operated by a third party and the personal information that you
              share with Sevenpay is transferred and shared with such third party
              payment gateway operator. The said operator may also have access
              to your online purchase history/details that you make from
              www.sevenpay.in. Extremely sensitive information like your credit
              card details are transacted upon secure sites digitally under
              encryption, thereby providing the highest possible degree of care
              are available as per current technology. You are advised, however,
              that Internet technology is not 100% safe and you should exercise
              discretion on using the same. Sevenpay also uses third party e-mail
              and SMS service providers to send transaction and marketing
              e-mails and messages to users transacting or using the
              www.sevenpay.in website. Sevenpay at its discretion may also use
              external vendors and providers, to ship out the orders for gift
              cards and gift vouchers received on www.sevenpay.in.Sevenpay may
              share your personal information like Name, Address, Phone number,
              etc to these service providers solely for the purpose of shipping
              out the user order. C. General Sevenpay will use information only
              in accordance with the Privacy Policy under which the information
              was collected unless we have received explicit authorization "We
              as a merchant shall be under no liability whatsoever in respect of
              any loss or damage arising directly or indirectly out of the
              decline of authorization for any Transaction, on Account of the
              Cardholder having exceeded the preset limit mutually agreed by us
              with our acquiring bank from time to time."
            </p>
          </Container>
        </div>
      </section>
    </>
  );
}

export default TermsAndConditions;
