import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import APIService from "../../services/api.service";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const AddressAddModel = (props) => {
  const { user } = useSelector((state) => state.user);

  const [address, setAddress] = useState({
    firstName: user?.name || "",
    lastName: "",
    addressType: "",
    address1: "",
    address2: "",
    landMark: "",
    city: "",
    state: "",
    country: "",
    pinCode: "",
    phoneCountryCode: "",
    phoneNumber: user?.phone || "",
    isDefault: "",
  });
  const onNewAddressAdded = props?.onNewAddressAdded;

  function handleInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    setAddress({
      ...address,
      [name]: value,
    });
  }

  function handleCheckBoxInput(e) {
    const name = e.target.name;
    const value = e.target.checked;
    // console.log(name, defaultValue);
    setAddress({
      ...address,
      [name]: value,
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    APIService.sendRequest("/api/v1/address", address, "POST").then(
      (response) => {
        // console.log(response);
        if (response?.success) {
          onNewAddressAdded();
          handleClose();
        } else {
          // error message
          showNotifications(response?.errorMessage);
        }
      }
    );
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const showNotifications = (msg) => {
    if (msg?.length > 0)
      toast(msg, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // className: "toaster_custom_color",
        // bodyClassName: "toaster_custom_body_color",
      });
  };

  return (
    <>
      <Button variant="mb-1" size="md" onClick={handleShow}>
        + Add New Address
      </Button>

      <Modal show={show} onHide={handleClose} className="add-address">
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="First name"
                name="firstName"
                value={address.firstName}
                onChange={handleInput}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last name"
                name="lastName"
                value={address.lastName}
                onChange={handleInput}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Phone Number"
                name="phoneNumber"
                value={address.phoneNumber}
                onChange={handleInput}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Address </Form.Label>
              <Form.Control
                type="text"
                placeholder="Address line 1"
                name="address1"
                value={address.address1}
                onChange={handleInput}
              />
              <Form.Control
                className="mt-3"
                placeholder="Address line 2"
                type="text"
                name="address2"
                value={address.address2}
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>LandMark </Form.Label>
              <Form.Control
                type="text"
                placeholder="Landmark"
                name="landMark"
                value={address.landMark}
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Pin Code </Form.Label>
              <Form.Control
                type="text"
                placeholder="Pincode"
                name="pinCode"
                value={address.pinCode}
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City </Form.Label>
              <Form.Control
                type="text"
                placeholder="City"
                name="city"
                value={address.city}
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>State </Form.Label>
              <Form.Control
                type="text"
                placeholder="State"
                name="state"
                value={address.state}
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Country </Form.Label>
              <Form.Control
                type="text"
                placeholder="Country"
                name="country"
                value={address.country}
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Address Type </Form.Label>{" "}
              <Form.Check
                inline
                label="Home"
                name="addressType"
                value="Home"
                type="radio"
                id={`inline-radio-1`}
                onChange={handleInput}
                checked={address.addressType === "Home" ? true : false}
              />
              <Form.Check
                inline
                label="Office"
                name="addressType"
                value="Office"
                type="radio"
                id={`inline-radio-1`}
                onChange={handleInput}
                checked={address.addressType === "Office" ? true : false}
              />
              <Form.Check
                inline
                label="Other"
                name="addressType"
                value="Other"
                type="radio"
                id={`inline-radio-1`}
                onChange={handleInput}
                checked={address.addressType === "Other" ? true : false}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Use this address as Default </Form.Label>{" "}
              <Form.Check
                type="checkbox"
                id="isDefault"
                name="isDefault"
                onChange={handleCheckBoxInput}
                checked={address.isDefault}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Add Address
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddressAddModel;
