import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { MdArrowBack } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import APIService from "../../services/api.service";
import Loader from "../loader/Loader";
import "./VerifyMobile.css";

function VerifyMobile() {
  const firstRef = useRef();
  const secondRef = useRef();
  const thirdRef = useRef();
  const fourthRef = useRef();
  const [otpSent, setOtpSent] = useState(false);
  // const [otpVerified, setOtpVerified] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");

  const handleSubmit = (event) => {
    // TODO: update user data
    event.preventDefault();
    // console.log(firstRef.current.value);
    // console.log(secondRef.current.value);
    // console.log(thirdRef.current.value);
    // console.log(fourthRef.current.value);
    var totalOtp =
      firstRef?.current?.value +
      secondRef?.current?.value +
      thirdRef?.current?.value +
      fourthRef?.current?.value;

    APIService.sendRequest(
      "/auth/verify/phone",
      { otp: totalOtp },
      "POST"
    ).then((response) => {
      // console.log(response);
      if (response.success) {
        // setOtpVerified(true);
        // setLoading(false);
        navigate("/profile/mobile/verified");
      } else {
        setIsError(true);
        setErrorMessage(response.errorMessage);
        // setLoading(false);
      }
    });
  };
  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  useEffect(() => {
    // setOtpSent(true);
    // setLoading(false);
    APIService.sendRequest("/auth/send/phone-verification", {}, "POST")
      .then((response) => {
        // console.log(response);
        if (response.success) {
          setOtpSent(true);
        } else {
          setIsError(true);
          setErrorMessage(response.errorMessage);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="dark">
        <Container>
          <div>
            <Link to="/profiledetails" className="back-icon">
              <MdArrowBack />
            </Link>
            <h5 className="text-white m-3 float-start">Verify Mobile</h5>
          </div>
          <div></div>
        </Container>
      </Navbar>
      {isLoading && <Loader />}
      {otpSent && (
        <section className="otp-verify">
          <h4>We have sent you OTP on Mobile Number</h4>
          <input
            type="text"
            ref={firstRef}
            maxLength={1}
            value={otp.slice(0, 1)}
            onChange={(event) => {
              if (
                event.target.value !== "" &&
                event.target.value.length === 1 &&
                isNumeric(event.target.value)
              ) {
                setOtp(otp + event.target.value);
                secondRef.current.focus();
              } else {
                if (event.target.value.length > 1) {
                  setOtp(event.target.value.slice(1, 2) + otp.slice(1));
                  secondRef.current.focus();
                } else {
                  setOtp(otp.slice(1));
                }
              }
            }}
          />
          <input
            type="text"
            ref={secondRef}
            maxLength={1}
            value={otp.slice(1, 2)}
            onChange={(event) => {
              if (
                event.target.value !== "" &&
                event.target.value.length === 1 &&
                isNumeric(event.target.value)
              ) {
                setOtp(otp + event.target.value);
                thirdRef.current.focus();
              } else {
                if (event.target.value.length > 1) {
                  setOtp(
                    otp.slice(0, 1) +
                      event.target.value.slice(1, 2) +
                      otp.slice(2)
                  );
                  thirdRef.current.focus();
                } else {
                  setOtp(otp.slice(0, 1) + otp.slice(2));
                  firstRef.current.focus();
                }
              }
            }}
          />
          <input
            type="text"
            ref={thirdRef}
            maxLength={1}
            value={otp.slice(2, 3)}
            onChange={(event) => {
              if (
                event.target.value !== "" &&
                event.target.value.length === 1 &&
                isNumeric(event.target.value)
              ) {
                setOtp(otp + event.target.value);
                fourthRef.current.focus();
              } else {
                if (event.target.value.length > 1) {
                  setOtp(
                    otp.slice(0, 2) +
                      event.target.value.slice(1, 2) +
                      otp.slice(3)
                  );
                  fourthRef.current.focus();
                } else {
                  setOtp(otp.slice(0, 2) + otp.slice(3));
                  secondRef.current.focus();
                }
              }
            }}
          />
          <input
            type="text"
            ref={fourthRef}
            maxLength={1}
            value={otp.slice(3, 4)}
            onChange={(event) => {
              if (
                event.target.value !== "" &&
                event.target.value.length === 1 &&
                isNumeric(event.target.value)
              ) {
                setOtp(otp + event.target.value);
                fourthRef.current.blur();
              } else {
                if (event.target.value.length > 1) {
                  setOtp(
                    otp.slice(0, 3) +
                      event.target.value.slice(1, 2) +
                      otp.slice(4)
                  );
                  fourthRef.current.blur();
                } else {
                  setOtp(otp.slice(0, 3) + otp.slice(4));
                  thirdRef.current.focus();
                }
              }
            }}
          />
          <p>
            Didn’t received OTP? <Link to="">Resend</Link>{" "}
          </p>
          <Button
            variant="primary"
            size="lg"
            className="w-100 mt-3"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </section>
      )}
      {isError && (
        <section className="otp-verify">
          <div className="desktop-wrapper mt-5">
            <Container className="cart-wrapper">
              <div className="details-card text-center">
                <img src="../../img/incorrect.png" alt="fail-img" />
                <p className="text-danger p-4">{errorMessage}</p>
              </div>
            </Container>
          </div>
        </section>
      )}
    </>
  );
}

export default VerifyMobile;
