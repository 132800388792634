import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {
  MdArrowBack,
  MdLogout,
  MdOutlineLiveHelp,
  MdOutlinePermIdentity,
  MdOutlineShoppingBag,
} from "react-icons/md";
import CustomBottomNav from "../CustomBottomNav";

function Profile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user.user);

  return (
    <>
      <Navbar
        className="desktop-view"
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="light"
      >
        <Container>
          <div>
            <Link to={"/"} className="back-icon">
              <MdArrowBack />
            </Link>
            <h5 className="text-white m-3 float-start">Profile</h5>
          </div>
        </Container>
      </Navbar>
      <div className="bg-desk">
        <div className="desktop-wrapper">
          <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container>
              <div>
                <h5 className="text-white m-2">Hi {user.name}!</h5>
                <p className="text-white m-2">Manage your profile and orders</p>
              </div>

              <Nav>
                <Nav.Link eventKey={2} to="#memes"></Nav.Link>
              </Nav>
            </Container>
          </Navbar>
          <section>
            <ListGroup>
              <Link to="/myorders">
                <ListGroup.Item>
                  <MdOutlineShoppingBag />
                  <h6>My Orders</h6>
                  <p>View all your bookings & purchases</p>
                </ListGroup.Item>
              </Link>
              <Link to="/profiledetails">
                <ListGroup.Item>
                  <MdOutlinePermIdentity />
                  <h6>Profile Details</h6>
                  <p>Profile, Payments, Permissions & More</p>
                </ListGroup.Item>
              </Link>
              <Link to="/help">
                <ListGroup.Item>
                  <MdOutlineLiveHelp />
                  <h6>Help & Support</h6>
                  <p>View commonly asked queries and Chat</p>
                </ListGroup.Item>
              </Link>
              <Link to="/about">
                <ListGroup.Item>
                  <MdOutlineLiveHelp />
                  <h6>About Sevenpay</h6>
                  <p>Contact us, Privacy Policy, Refund</p>
                </ListGroup.Item>
              </Link>

              <Link to="/logout">
                <ListGroup.Item>
                  <MdLogout />
                  <h6>Logout</h6>
                  <p>View all your bookings & purchases</p>
                </ListGroup.Item>
              </Link>
            </ListGroup>
          </section>
        </div>
        <CustomBottomNav />
      </div>
    </>
  );
}

export default Profile;
