import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import APIService from "../../services/api.service";

const BannerCarousel = () => {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    APIService.sendRequest("/api/v1/banner").then((response) => {
      setBanners(response?.banners);
      // console.log(response?.banners);
    });
  }, []);

  return (
    <section>
      <div className="container">
        <Carousel indicators={false}>
          {banners?.map((banner, i) => (
            <Carousel.Item key={i}>
              <img
                className="desktop-view w-100 rounded"
                src={banner.large}
                alt="desktopimg"
                fetchpriority="high"
              />
              <img
                className="mobile-view w-100 rounded"
                src={banner.small}
                alt="mobileimg"
                fetchpriority="high"
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default BannerCarousel;
