import React from "react";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { MdArrowBack, MdOutlineShoppingCart } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const CustomNavBar = (props) => {
  const title = props.title;
  const referrer = props.referrer;
  const to = props.to;
  // console.log(title, referrer);
  const navigate = useNavigate();
  const { products: cartProducts } = useSelector((state) => state.cart);

  // TODO: redirect to home, when there is no back page (navigate(-1))

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <div>
          {to && (
            <Link className="back-icon" to={to}>
              <MdArrowBack />
            </Link>
          )}
          {!to && (
            <Link
              className="back-icon"
              onClick={(event) => {
                navigate(-1);
              }}
            >
              <MdArrowBack />
            </Link>
          )}

          <h5 className="text-white m-3 float-start">{title}</h5>
        </div>
        <div>
          <Link to="/cart" className="cart-icon">
            <MdOutlineShoppingCart />
            <span className="badge badge-warning" id="lblCartCount">
              {" "}
              {cartProducts?.length}{" "}
            </span>
          </Link>
        </div>
        {/* TODO: operate based on sign in status */}
        {/* <div className="desktop-view">
            <Button className="me-5" variant="primary" size="sm">
              Login/Register
            </Button>
          </div> */}
      </Container>
    </Navbar>
  );
};

export default CustomNavBar;
