import { createSlice } from "@reduxjs/toolkit";
import APIService from "../services/api.service";

const initialState = {
  offers: [],
};
const offerSlice = createSlice({
  name: "offer",
  initialState,
  reducers: {
    setOffers(state, action) {
      // console.log("action.payload in offers", action.payload);
      state.offers = action.payload?.data?.offers;
    },
  },
});

export const getOffers = () => {
  return async (dispatch) => {
    try {
      const response = await APIService.sendRequest("/api/v1/offer");
      // console.log(response);
      if (response?.success) {
        // console.log(response.success);
        dispatch(offerActions.setOffers(response));
      }
    } catch (err) {
      // console.log(err);
    }
  };
};

export const offerActions = offerSlice.actions;
export default offerSlice.reducer;
