import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { MdCheckCircle } from "react-icons/md";
import { isBrowser } from "react-device-detect";
import { useNavigate } from "react-router-dom";

const OrderSuccess = (props) => {
  const date = props.date;
  const orderId = props.orderId;
  const navigate = useNavigate();
  // TODO: fetch date and order details here

  useEffect(() => {
    if (isBrowser) {
      navigate("/order/success");
    }
  }, []);
  return (
    <Container className="cart-wrapper">
      <div className="status-bar status-bar-success">
        <MdCheckCircle className="icon" />
        <br />
        <h4>Your Order has been placed</h4>
      </div>
      <div className="order-id d-flex">
        <p>Order placed on: {date?.slice(0, 10)}</p>
        <p>Order ID: #{orderId}</p>
      </div>
    </Container>
  );
};

export default OrderSuccess;
