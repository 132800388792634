import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const GiftCard = (props) => {
  const image = props.image;
  const name = props.name;
  const discount = props.discount || 0;
  const productId = props.productId;
  const price = props.price;
  const expiry = props.expiry;

  return (
    <Link to={"/giftcards/" + productId} className="giftcard-link">
      <div className="listing-card d-flex">
        <Card.Img className="gift-img" variant="top" src={image} alt="giftcard_image"/>
        <Card.Body>
          <Card.Title>{name}</Card.Title>
          <Card.Text>
            From: ₹{price} <br />
            <small>Expiry: {expiry}</small>
          </Card.Text>
        </Card.Body>
      </div>
    </Link>
  );
};

export default GiftCard;
