import { createSlice } from "@reduxjs/toolkit";
import APIService from "../services/api.service";

const initialState = {
  products: [],
  total: 0,
  finalTotal: 0,
  success: false,
  errorMessage: "",
  totalDiscount: 0,
  gateway: {},
  orderId: "",
  orders: [],
};
const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrderProducts(state, action) {
      // console.log("action.payload in order", action.payload);
      state.products = action.payload?.order.products;
      state.success = true;
    },
    setOrder(state, action) {
      // console.log("action.payload in order", action.payload);
      state.products = action.payload?.order.products;
      state.gateway = action.payload?.order.gateway;
      state.orderId = action.payload?.order.orderId;
      state.success = true;
    },
    setOrders(state, action) {
      state.orders = action.payload?.orders;
      state.success = true;
    },

    setError(state, action) {
      state.success = false;
      state.errorMessage = action.payload?.errorMessage;
    },
  },
});

export const getOrderProducts = () => {
  return async (dispatch) => {
    try {
      const response = await APIService.sendRequest("/api/v1/order");
      // console.log(response);
      if (response?.success) {
        // console.log(response.success);
        dispatch(orderActions.setOrderProducts(response));
      }
      // if (!response?.success) dispatch(orderActions.setError(response));
    } catch (err) {
      // console.log(err);
    }
  };
};

export const getOrders = () => {
  return async (dispatch) => {
    try {
      const response = await APIService.sendRequest("/api/v1/order");
      // console.log(response);
      if (response?.success) {
        // console.log(response.success);
        dispatch(orderActions.setOrders(response));
      }
      // if (!response?.success) dispatch(orderActions.setError(response));
    } catch (err) {
      // console.log(err);
    }
  };
};

export const createOrder = (order) => {
  return async (dispatch) => {
    try {
      const response = await APIService.sendRequest(
        "/api/v1/order",
        order,
        "POST"
      );
      // console.log(response);
      if (response?.success) {
        // console.log(response.success);
        dispatch(orderActions.setOrder(response));
      }
      if (!response?.success) dispatch(orderActions.setError(response));
    } catch (err) {
      // console.log(err);
    }
  };
};

export const orderActions = orderSlice.actions;
export default orderSlice.reducer;
