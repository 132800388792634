import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { MdClear } from "react-icons/md";
import { isBrowser } from "react-device-detect";
import { useNavigate } from "react-router-dom";

const OrderFailed = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isBrowser) {
      navigate("/order/failure");
    }
  }, []);

  return (
    <Container className="cart-wrapper">
      <div className="status-bar status-bar-failure">
        <MdClear className="icon" />
        <br />
        <h4>Payment Failed</h4>
      </div>
      {/* <div className="order-id d-flex">
        <p>Order placed on: {date?.slice(0, 10)}</p>
        <p>Order ID: #{orderId}</p>
      </div> */}
    </Container>
  );
};

export default OrderFailed;
