import Container from "react-bootstrap/Container";
import CustomNavBar from "./CustomNavBar";

function Privacy() {
  return (
    <>
      <CustomNavBar title={"Refund Policy"} />
      <section className="profile-summary-wrapper">
        <div className="desktop-wrapper">
          <Container className="cart-wrapper">
            <p>
              Gift Cards & Brand Gift Vouchers, in Electronic or Physical bought
              on sevenpay.in cannot be returned/ refunded. If you've received a
              non-returnable physical GIFT CARD or BRAND GIFT VOUCHERS product
              in a damaged/defective condition, you can contact us within 7 days
              from the delivery of the product. Under no circumstances the
              return of such product will be entertained post 7 days of
              delivery. Order placed once can be Cancelled till the time the
              order is in pending status, once the order is accepted and
              dispatched by sevenpay, cancellation of purchase will not be
              allowed under any circumstances.
            </p>
          </Container>
        </div>
      </section>
    </>
  );
}

export default Privacy;
