import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { Button, Form } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {
  MdOutlinePerson,
  MdOutlineShoppingCart,
  MdSearch,
} from "react-icons/md";
import logo from "../../sevenpay_logo.svg";
import Drawer from "./Drawer";
import Search from "./Search";
import { getCartProducts } from "../../store/cartSlice";
const Header = (props) => {
  const title = props.title || (
    <Navbar.Brand as={Link} to="/">
      <img src={logo} alt="logo" />
    </Navbar.Brand>
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoggedIn, token, user, error, errorMessage } = useSelector(
    (state) => state.user
  );

  const { products } = useSelector((state) => state.cart);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    // if (!user?.phoneVerified) {
    //   navigate("/profile/verify/mobile");
    // }
    // if (!user?.emailVerified) {
    // }
    if (isLoggedIn) dispatch(getCartProducts());
  }, []);
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
      <Container className="position-relative">
        <div>{title}</div>
        <div>
          {isLoggedIn && (
            <Link
              className="desktop-view profile-menu"
              size="sm"
              variant="secondary"
              onClick={handleShow}
            >
              <MdOutlinePerson />
            </Link>
          )}

          <Drawer show={show} handleClose={handleClose} name={user?.name} />
          <Link to="/cart" className="cart-icon" aria-label="cart">
            <MdOutlineShoppingCart />
            {products?.length > 0 && (
              <span className="badge badge-warning" id="lblCartCount">
                {" "}
                {products?.length}{" "}
              </span>
            )}
          </Link>
        </div>

        <Search />
        {!isLoggedIn && (
          <div className="desktop-view">
            <Button href="/login" className="me-5" variant="primary" size="sm">
              Login/Register
            </Button>
          </div>
        )}
      </Container>
    </Navbar>
  );
};

export default Header;
