import React from "react";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { MdArrowBack } from "react-icons/md";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const EmailVerifyRequest = () => {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container>
          <div>
            <Link to="/profiledetails" className="back-icon">
              <MdArrowBack />
            </Link>
            <h5 className="text-white m-3 float-start">Email Verification</h5>
          </div>
        </Container>
      </Navbar>

      <section>
        <div className="desktop-wrapper mt-5">
          <Container className="cart-wrapper">
            <div className="details-card text-center">
              <p>You have been sent email verification link.</p>
              <p>Please verify your email.</p>
              <Link to="/">
                <Button variant="primary" size="lg" className="mt-3">
                  Home
                </Button>
              </Link>
            </div>
          </Container>
        </div>
      </section>
      {/* )} */}
    </>
  );
};

export default EmailVerifyRequest;
