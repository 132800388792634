import React, { useRef, useState } from "react";

import Container from "react-bootstrap/Container";

import Navbar from "react-bootstrap/Navbar";
import { Button, Form } from "react-bootstrap";
import { MdArrowBack } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import APIService from "../../services/api.service";

function ResetPassword() {
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const userId = params.userId;
  const hash = params.hash;

  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    if (passwordRef?.current?.value !== confirmPasswordRef?.current?.value) {
      showNotifications("passwords did not match");
      return;
    }

    APIService.sendRequest(
      "/auth/password/forgot",
      {
        userId,
        hash,
        newPassword: passwordRef?.current?.value,
      },
      "POST"
    ).then(async (response) => {
      // console.log(response);
      if (!response) {
        showNotifications("something went wrong");
        return;
      }
      if (!response.success) {
        showNotifications(response.errorMessage);
      } else {
        showNotifications("Password changed");
        // const sleep = (milliseconds) => {
        //   return new Promise((resolve) => setTimeout(resolve, milliseconds));
        // };
        // await sleep(5000);
        navigate("/login");
      }
    });
  };

  const showNotifications = (msg) => {
    toast(msg, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      // className: "toaster_custom_color",
      // bodyClassName: "toaster_custom_body_color",
    });
  };

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container>
          <div>
            <Link href="/" className="back-icon">
              <MdArrowBack />
            </Link>
            <h5 className="text-white m-3 float-start">Reset Password</h5>
          </div>
        </Container>
      </Navbar>
      <section>
        <div className="desktop-wrapper">
          <Container className="cart-wrapper">
            <div className="details-card p-4">
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formPassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder=""
                    ref={passwordRef}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formConfirmPassword">
                  <Form.Label>Confirm New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder=""
                    ref={confirmPasswordRef}
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  size="lg"
                  className="w-100 mt-3"
                  type="submit"
                >
                  Confirm
                </Button>
              </Form>
            </div>
          </Container>
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </section>
    </>
  );
}

export default ResetPassword;
