import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { MdClear } from "react-icons/md";
import { isBrowser } from "react-device-detect";
import { useNavigate } from "react-router-dom";

const OrderCancel = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isBrowser) {
      navigate("/order/cancel");
    }
  }, []);

  return (
    <Container className="cart-wrapper">
      <div className="status-bar status-bar-failure">
        <MdClear className="icon" />
        <br />
        <h4>Payment Cancelled</h4>
      </div>
    </Container>
  );
};

export default OrderCancel;
