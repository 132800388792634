import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./cartSlice";
import giftCardReducer from "./giftCardSlice";
import offerReducer from "./offerSlice";
import orderReducer from "./orderSlice";
import userReducer from "./userSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    giftCard: giftCardReducer,
    cart: cartReducer,
    order: orderReducer,
    offer: offerReducer,
  },
});

export default store;
