import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const OrderProduct = (props) => {
  const name = props.name;
  const image = props.image;
  const price = props.price;
  const expiry = props.expiry;
  const orderId = props.orderId;
  const date = props.date;
  const statusLabel = props.statusLabel;

  return (
    <Link to={"/myorders/" + orderId} className="giftcard-link">
      <div className="listing-card d-flex">
        <Card.Img className="gift-img" variant="top" src={image} alt="orderproductimage"/>
        <Card.Body>
          <Card.Title>{name}</Card.Title>
          <Card.Text>
            ₹{price} <br />
            <small>Expiry: {expiry || "NA"}</small>
            <br />
            <small>Status: {statusLabel}</small>
          </Card.Text>
        </Card.Body>
        <div className="order-status-p">
          <p>Order Id: #{orderId}</p>
          <p>Date: {date}</p>
        </div>
      </div>
    </Link>
  );
};

export default OrderProduct;
