import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { MdArrowBack, MdCheckCircle } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

import { userActions } from "../../store/userSlice";
import { Button } from "react-bootstrap";

const ForgotPasswordSuccess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      userActions.setForgotPwdEmailStatus({ sentForgotPasswordEmail: false })
    );
  }, []);

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container>
          <div>
            <Link to="/" className="back-icon">
              <MdArrowBack />
            </Link>
            <h5 className="text-white m-3 float-start">Forget Password</h5>
          </div>
        </Container>
      </Navbar>

      <section>
        <div className="desktop-wrapper mt-5">
          <Container className="cart-wrapper">
            <div className="details-card text-center">
              <img
                src="../../img/success.svg"
                className="success-img"
                alt="successimg"
              />
              <h3 className="green-text">Mail sent!</h3>
              <p>You have been sent a mail with reset password link.</p>
              <Button
                variant="primary"
                size="lg"
                className="mt-3"
                onClick={() => {
                  navigate("/");
                }}
              >
                Home
              </Button>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default ForgotPasswordSuccess;
