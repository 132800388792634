import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import { MdModeEditOutline, MdVerified } from "react-icons/md";
import { Link } from "react-router-dom";
import CustomNavBar from "../CustomNavBar";

import { getAddresses, userActions } from "../../store/userSlice";

function Profile() {
  const dispatch = useDispatch();
  const { user, addresses } = useSelector((state) => state.user);
  // console.log("user", user);
  var address = addresses?.filter((address) => address.isDefault === true);
  if (address?.length) address = address[0];
  // console.log("address", address);

  useEffect(() => {
    dispatch(getAddresses());
    dispatch(userActions.setUserUpdateSuccess({ success: false }));
  }, []);
  return (
    <>
      <CustomNavBar title={"Profile Details"} />
      <section className="profile-summary-wrapper">
        <div className="gift-details cart-ro pb-24 pt-24">
          <div className="desktop-wrapper">
            <Container className="cart-wrapper">
              <h4>Personal Information</h4>
              <ListGroup>
                <Link to="/profileedit">
                  <ListGroup.Item>
                    <p>Name</p>
                    <h6>{user?.name}</h6>
                    <MdModeEditOutline />
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <p>Mobile</p>
                    <h6>
                      {user?.phone}
                      {user?.phoneVerified && <MdVerified color="green" />}
                    </h6>

                    {!user?.phoneVerified && (
                      <Link to="/profile/verify/mobile">Verify</Link>
                    )}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <p>Email Address</p>
                    <h6>
                      {user?.email}
                      {user?.emailVerified && <MdVerified color="green" />}
                    </h6>
                    {!user?.emailVerified && (
                      <Link to="/profile/verify/email">Verify</Link>
                    )}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <p>Date of Birth</p>
                    <h6>{user?.dob?.slice(0, 10)}</h6>
                    <MdModeEditOutline />
                  </ListGroup.Item>
                </Link>
              </ListGroup>
              {/* <ListGroup>
                <h4 className="mt-4">Address</h4>
                <Link to="/profile/address/edit">
                  <ListGroup.Item>
                    <p>Default Address</p>
                    <h6>
                      {address?.firstName} {address?.lastName},{" "}
                      {address?.phoneNumber}
                    </h6>
                    <h6>
                      {address?.address1}, {address?.address2}
                    </h6>
                    <h6>
                      {address?.landMark}, {address?.pinCode}
                    </h6>
                    <h6>
                      {address?.city}, {address?.state}
                    </h6>
                    <MdModeEditOutline />
                  </ListGroup.Item>
                </Link>
              </ListGroup> */}

              <h4 className="mt-4">Change Password</h4>
              <ListGroup>
                <Link to="/profile/changepassword">
                  <ListGroup.Item>
                    <h6>Reset or change your password</h6>
                    <MdModeEditOutline />
                  </ListGroup.Item>
                </Link>
              </ListGroup>
            </Container>
          </div>
        </div>
      </section>
    </>
  );
}

export default Profile;
