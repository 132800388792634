import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, ButtonGroup, ButtonToolbar, Form } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { updateUser } from "../../store/userSlice";
import CustomNavBar from "../CustomNavBar";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function Profile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    user,
    isUserUpdateError,
    userUpdateErrorMessage,
    isUserUpdateSuccess,
  } = useSelector((state) => state.user);

  const nameRef = useRef();
  const phoneRef = useRef();
  const dobRef = useRef();
  // const genderRef = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(
    //   nameRef.current.value,
    //   phoneRef.current.value,
    //   dobRef.current.value
    // );

    // var data = {};
    const formData = new FormData();
    // formData.append("file", file);
    // formData.append("name", user.name);
    // formData.append("phone", user.phone);
    // formData.append("email", user.email);
    // formData.append("role", user.role);
    // formData.append("gender", user.gender);
    // formData.append("dob", user.dob);

    if (nameRef?.current?.value) {
      formData.append("name", nameRef.current.value);
    }
    if (phoneRef?.current?.value) {
      formData.append("phone", phoneRef.current.value);
    }
    if (dobRef?.current?.value) {
      formData.append("dob", dobRef.current.value);
    }

    // console.log("data", formData);
    // if (data && Object.keys(data).length > 0) {
    // console.log("data length", data.length);
    dispatch(updateUser(formData));
    // }
  };

  useEffect(() => {
    if (isUserUpdateSuccess) navigate(-1);
  }, [isUserUpdateSuccess, navigate]);

  return (
    <>
      <CustomNavBar title={"Edit Profile"} />
      <section className="profile-summary-wrapper">
        <div className="gift-details cart-ro pb-24 pt-24">
          <div className="desktop-wrapper">
            <Container className="cart-wrapper">
              <h4>Personal Information</h4>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={user?.name}
                    ref={nameRef}
                  />
                </Form.Group>
                {!user?.phoneVerified && (
                  <Form.Group className="mb-3" controlId="formBasicPhone">
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      type="text"
                      defaultValue={user?.phone}
                      ref={phoneRef}
                    />
                  </Form.Group>
                )}
                <Form.Group className="mb-3" controlId="dob">
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control
                    type="date"
                    defaultValue={user?.dob?.slice(0, 10)}
                    ref={dobRef}
                  />
                </Form.Group>
                {/* <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>Gender</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={user?.gender}
                    ref={genderRef}
                  />
                </Form.Group> */}
                <div className="bottom-nav position-fixed">
                  <ButtonToolbar aria-label="Toolbar with button groups">
                    <ButtonGroup
                      size="lg"
                      className="me-2"
                      aria-label="First group"
                    >
                      <Button variant="secondary" type="submit">
                        Cancel
                      </Button>
                    </ButtonGroup>
                    <ButtonGroup size="lg" aria-label="Second group">
                      <Button variant="primary" type="submit">
                        Update
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                </div>
              </Form>
            </Container>
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Profile;
