import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import APIService from "../../services/api.service";
import CustomProductCard from "./CustomProductCard";
import { useSelector } from "react-redux";

const MostRecommendedProducts = () => {
  const { offers } = useSelector((state) => state.offer);
  // console.log("offers", offers);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    APIService.sendRequest("/api/v1/products/trending").then((response) => {
      setProducts(response?.products);
      // console.log(response);
    });
  }, []);

  return (
    <section className="scroll-hidden mb-5">
      <div className="container">
        <h4>
          Most Recommended{" "}
          <Link to={"/giftcards?type=mostrecommendproducts"}>View All</Link>{" "}
        </h4>
        <div className="horizontal-scroll d-flex card-scroll">
          {products &&
            products.map((product, i) => {
              // TODO: remove this logic
              var offs = offers.filter(
                (offer) => product.brandName === offer.productId.brandName
              );
              var dis = 0;
              if (offs.length > 0) dis = offs[0].discount;
              // console.log("offs", offs);

              return (
                <CustomProductCard
                  key={i}
                  image={product?.images?.custom || product?.images?.mobile}
                  name={product.name}
                  discount={dis}
                  productId={product._id}
                />
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default MostRecommendedProducts;
