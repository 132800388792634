import { Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import About from "./pages/About";
import BrandList from "./pages/BrandList";
import Help from "./pages/Help";
import HowItWorks from "./pages/HowItWorks";
import Privacy from "./pages/Privacy";
import Refund from "./pages/Refund";
import Cart from "./pages/cart/Cart";
import Checkout from "./pages/checkout/Checkout";
import GiftCardDetails from "./pages/giftcard/GiftCardDetails";
import GiftCards from "./pages/giftcard/GiftCards";
import Home from "./pages/home/Home";
import MyOrders from "./pages/order/MyOrders";
import OrderDetails from "./pages/order/OrderDetails";
import OrderStatus from "./pages/order/OrderStatus";
import AddressEdit from "./pages/user/AddressEdit";
import ChangePassword from "./pages/user/ChangePassword";
import ForgetPassword from "./pages/user/ForgetPassword";
import Login from "./pages/user/Login";
import Profile from "./pages/user/Profile";
import ProfileDetails from "./pages/user/ProfileDetails";
import ProfileEdit from "./pages/user/ProfileEdit";
import Register from "./pages/user/Register";
import VerifyMobile from "./pages/user/VerifyMobile";

import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import Page404 from "./pages/errorpages/Page404";
import Page500 from "./pages/errorpages/Page500";
import Logout from "./pages/logout/Logout";
import OrderPlacedCancelled from "./pages/order/OrderPlacedCancelled";
import OrderPlacedError from "./pages/order/OrderPlacedError";
import OrderPlacedFailed from "./pages/order/OrderPlacedFailed";
import OrderPlacedSuccess from "./pages/order/OrderPlacedSuccess";
import ProtectedRoute from "./pages/secureroute/ProtectedRoute";
import ChangePasswordSuccess from "./pages/user/ChangePasswordSuccess";
import EmailVerificationLinkSent from "./pages/user/EmailVerificationLinkSent";
import EmailVerified from "./pages/user/EmailVerified";
import EmailVerifyRequest from "./pages/user/EmailVerifyRequest";
import ForgotPasswordSuccess from "./pages/user/ForgotPasswordSuccess";
import MobileVerified from "./pages/user/MobileVerified";
import ResetPassword from "./pages/user/ResetPassword";
import ResetPasswordSuccess from "./pages/user/ResetPasswordSuccess";
import FileDownload from "./FileDownload";

function App() {
  return (
    <>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/home" element={<Home />}></Route>
            <Route path="/giftcards" element={<GiftCards />}></Route>
            <Route
              path="/giftcards/:productId"
              element={<GiftCardDetails />}
            ></Route>
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/profiledetails"
              element={
                <ProtectedRoute>
                  <ProfileDetails />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/profileedit"
              element={
                <ProtectedRoute>
                  <ProfileEdit />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/profile/verify/mobile"
              element={<VerifyMobile />}
            ></Route>
            <Route
              path="/profile/mobile/verified"
              element={<MobileVerified />}
            ></Route>
            <Route
              path="/profile/verify/email"
              element={<EmailVerificationLinkSent />}
            ></Route>
            <Route
              path="/email/verify/:userId/:hash"
              element={<EmailVerified />}
            ></Route>
            <Route
              path="/profile/changepassword"
              element={
                <ProtectedRoute>
                  <ChangePassword />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/profile/changepassword/success"
              element={<ChangePasswordSuccess />}
            ></Route>
            <Route
              path="/password/reset/success"
              element={<ResetPasswordSuccess />}
            ></Route>
            <Route
              path="/password/reset/:userId/:hash"
              element={<ResetPassword />}
            ></Route>
            <Route
              path="/profile/address/edit"
              element={
                <ProtectedRoute>
                  <AddressEdit />
                </ProtectedRoute>
              }
            ></Route>

            <Route
              path="/brandlist"
              element={
                // <ProtectedRoute>
                <BrandList />
                // </ProtectedRoute>
              }
            ></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/register" element={<Register />}></Route>
            <Route
              path="/register/request-to-email-verify"
              element={<EmailVerifyRequest />}
            ></Route>
            <Route
              path="/cart"
              element={
                <ProtectedRoute>
                  <Cart />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/myorders"
              element={
                <ProtectedRoute>
                  <MyOrders />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/order/success"
              element={<OrderPlacedSuccess />}
            ></Route>
            <Route
              path="/order/failure"
              element={<OrderPlacedFailed />}
            ></Route>
            <Route
              path="/order/cancel"
              element={<OrderPlacedCancelled />}
            ></Route>
            <Route path="/order/error" element={<OrderPlacedError />}></Route>

            <Route
              path="/myorders/:orderId"
              element={
                <ProtectedRoute>
                  <OrderDetails />
                </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/orderdetails"
              element={
                <ProtectedRoute>
                  <OrderDetails />
                </ProtectedRoute>
              }
            ></Route>
            {/* TODO: accepts only from backend as referrer */}
            <Route
              path="/payment/success/:orderId"
              element={
                // <ProtectedRoute>
                <OrderStatus />
                // </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/payment/failure/:orderId"
              element={
                // <ProtectedRoute>
                <OrderStatus />
                // </ProtectedRoute>
              }
            ></Route>
            <Route
              path="/payment/cancel/:orderId"
              element={
                // <ProtectedRoute>
                <OrderStatus />
                // </ProtectedRoute>
              }
            ></Route>
            <Route path="/forgotpassword" element={<ForgetPassword />}></Route>
            <Route
              path="/forgot-password/success"
              element={<ForgotPasswordSuccess />}
            ></Route>

            <Route path="/help" element={<Help />}></Route>
            <Route path="/aboutus" element={<About />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/contact" element={<About />}></Route>
            <Route path="/contactus" element={<About />}></Route>
            <Route path="/howitworks" element={<HowItWorks />}></Route>
            <Route path="/privacy" element={<PrivacyPolicy />}></Route>
            <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
            <Route
              path="/termsandconditions"
              element={<TermsAndConditions />}
            ></Route>

            <Route path="/refund" element={<Refund />}></Route>
            <Route path="/refundpolicy" element={<Refund />}></Route>

            <Route
              path="/checkout"
              element={
                <ProtectedRoute>
                  <Checkout />
                </ProtectedRoute>
              }
            ></Route>
            <Route path="/logout" element={<Logout />}></Route>
            <Route path="/500" element={<Page500 />}></Route>
            <Route
              path="/.well-known/pki-validation/9A0FFD85615BC423E21436311D698924.txt"
              element={<FileDownload />}
            ></Route>
            <Route path="/*" element={<Page404 />}></Route>
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
