import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";

import Navbar from "react-bootstrap/Navbar";
import { Button, Badge, Form, Card } from "react-bootstrap";
import {
  MdOutlineFilterList,
  MdCampaign,
  MdSearch,
  MdArrowBack,
} from "react-icons/md";

function ResetPasswordSuccess() {
  return (
    <section>
      <div className="desktop-wrapper mt-5">
        <Container className="cart-wrapper">
          <div className="details-card text-center">
            <img
              src="../../img/success.svg"
              className="success-img"
              alt="success-img"
            />
            <h3 className="green-text">Password Changed!</h3>
            <p>Your password has been changed successfully.</p>
            <Button variant="primary" size="lg" className="mt-3">
              Login
            </Button>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default ResetPasswordSuccess;
