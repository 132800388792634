import React from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import "./CartEmpty.css";

const CartEmpty = () => {
  const navigate = useNavigate();
  return (
    <section>
      <div className="desktop-wrapper cart-center-adjust">
        <Container className="cart-wrapper">
          <div className="details-card text-center">
            <img
              src="../../img/shopping-cart.png"
              className="success-img"
              alt="successimg"
            />
            <h3 className="mt-3 grey-text">Your cart is empty!</h3>
            {/* <p>Your phone number has been verified successfully.</p> */}
            {/* <Button
              variant="primary"
              size="lg"
              className="mt-3"
              onClick={() => {
                navigate("/myorders");
              }}
            >
              My Orders
            </Button> */}
          </div>
        </Container>
      </div>
    </section>
  );
};

export default CartEmpty;
