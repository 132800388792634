import React from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const OrderPlacedError = () => {
  const navigate = useNavigate();

  return (
    <section>
      <div className="desktop-wrapper mt-5">
        <Container className="cart-wrapper">
          <div className="details-card text-center">
            <img
              src="../../img/incorrect.png"
              className="success-img"
              alt="successimg"
            />
            <h3 className="red-text">Error Occured!</h3>
            <p>while placing your order.</p>
            <Button
              variant="primary"
              size="lg"
              className="mt-3"
              onClick={() => {
                navigate("/myorders");
              }}
            >
              My Orders
            </Button>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default OrderPlacedError;
