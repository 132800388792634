import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { Button, Form } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { MdArrowBack } from "react-icons/md";

import { changePassword } from "../../store/userSlice";

function ChangePassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isPasswordChanged = useSelector(
    (state) => state.user.isPasswordChanged
  );
  // console.log("isPasswordChanged", isPasswordChanged);

  // TODO: change to refs
  const [auth, setAuth] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  function handleInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    // console.log(name, value);
    setAuth({
      ...auth,
      [name]: value,
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsError(false);
    setErrorMessage("");
    if (auth?.newPassword !== auth?.confirmPassword) {
      setIsError(true);
      setErrorMessage("Password did not matched");
    } else dispatch(changePassword(auth));
    // redirect to changepassword success page
  }

  useEffect(() => {
    if (isPasswordChanged) navigate("/profile/changepassword/success");
  }, [isPasswordChanged, navigate]);

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container>
          <div>
            <Link to="/profiledetails" className="back-icon">
              <MdArrowBack />
            </Link>
            <h5 className="text-white m-3 float-start">Change Password</h5>
          </div>
        </Container>
      </Navbar>
      <section>
        <div className="bg-desk">
          <div className="container-md">
            <div className="details-card">
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Old Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Current password"
                    name="oldPassword"
                    defaultValue={auth.oldPassword}
                    onChange={handleInput}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder=""
                    name="newPassword"
                    defaultValue={auth.newPassword}
                    onChange={handleInput}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder=""
                    name="confirmPassword"
                    defaultValue={auth.confirmPassword}
                    onChange={handleInput}
                    isInvalid={isError}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorMessage}
                  </Form.Control.Feedback>
                </Form.Group>

                <Button
                  variant="primary"
                  size="lg"
                  className="w-100 mt-3"
                  type="submit"
                >
                  Change
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChangePassword;
