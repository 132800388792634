import React from "react";
import { Link } from "react-router-dom";

const SearchItem = (props) => {
  const product = props.product;
  return (
    <div className="search-item">
      <Link to={"/giftcards/" + product._id}>{product.name}</Link>
    </div>
  );
};

export default SearchItem;
