import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { Button, Col, Form, Row, Tab } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {
  MdArrowBack,
  MdOutlineFilterList,
  MdOutlineShoppingCart,
  MdSearch,
} from "react-icons/md";
import InfiniteScroll from "react-infinite-scroll-component";

import { getProducts } from "../../store/giftCardSlice";
import CustomBottomNav from "../CustomBottomNav";
import Loader from "../loader/Loader";
import GiftCard from "./GiftCard";
import APIService from "../../services/api.service";
import { ThreeDots } from "react-loader-spinner";

function GiftCards() {
  // TODO: retain brands state
  // TODO: get multiple brands names from urls
  // TODO: product limit
  // TODO: handle API success:false cases
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const [brand, setBrand] = useState(searchParams.get("brand"));
  const { products: cartProducts } = useSelector((state) => state.cart);
  const { isLoggedIn } = useSelector((state) => state.user);
  // console.log("products", products);

  // infinite scrolling
  const [page, setPage] = useState(0);
  const [totalGifts, setTotalGifts] = useState(0);
  const [limit, setLimit] = useState(20);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadMore = () => {
    // console.log(page, products.length);
    const p = page + 1;
    var url = "/api/v1/products?page=" + p + "&limit=" + limit;
    if (brand) url = url + "&brand=" + brand;

    APIService.sendRequest(url).then((response) => {
      if (response.success) {
        if (response?.products?.length > 0) {
          setProducts((p) => [...p, ...response.products]);
          setTotalGifts(response.total);
          setPage((page) => page + 1);
        }
      }
    });
  };

  const fetchProducts = () => {
    setIsLoading(true);
    // console.log(page, products.length);
    const p = 1;
    var url = "/api/v1/products?page=" + p + "&limit=" + limit;
    if (brand) url = url + "&brand=" + brand;

    APIService.sendRequest(url)
      .then((response) => {
        if (response.success) {
          if (response?.products?.length > 0) {
            setProducts(response.products);
            setTotalGifts(response.total);
            setPage(1);
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleMultipleBrands = (b) => {
    // console.log(b, brand);
    if (brand && brand !== "") {
      setBrand(brand + "," + b);
    } else {
      setBrand(b);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  // useEffect(() => {
  //   if (brand) dispatch(getProducts(brand));
  //   else dispatch(getProducts());
  // }, [dispatch, brand]);

  const brands = [
    { name: "Flipkart", image: "../img/flipkart.svg" },
    { name: "Myntra", image: "../img/myntra.svg" },
    { name: "Bookmyshow", image: "../img/bookmyshow.svg" },
    { name: "Max", image: "../img/flipkart.svg" },
    { name: "MakeMyTrip", image: "../img/myntra.svg" },
    { name: "Bigbasket", image: "../img/bookmyshow.svg" },
    { name: "Dominos", image: "../img/flipkart.svg" },
    { name: "Zomato", image: "../img/myntra.svg" },
    { name: "Swiggy", image: "../img/bookmyshow.svg" },
  ];

  const categories = [
    // { name: "Shopping", image: "../img/shopping.svg" },
    // { name: "Entertainment", image: "../img/entertainment.svg" },
    // { name: "Home & Grocery", image: "../img/home-groccery.svg" },
    // { name: "Accessories & Jewellery", image: "../img/shopping.svg" },
    // { name: "Beauty & Wellness", image: "../img/shopping.svg" },
    // { name: "Dining", image: "../img/shopping.svg" },
    // { name: "Shopping", image: "../img/shopping.svg" },
    // { name: "Entertainment", image: "../img/entertainment.svg" },
    // { name: "Home & Grocery", image: "../img/home-groccery.svg" },
    // { name: "Accessories & Jewellery", image: "../img/shopping.svg" },
    // { name: "Beauty & Wellness", image: "../img/shopping.svg" },
    // { name: "Dining", image: "../img/shopping.svg" },
  ];

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        sticky="top"
      >
        <Container className="position-relative">
          <div>
            <Link to="/" className="back-icon">
              <MdArrowBack />
            </Link>
            <h5 className="text-white m-3 float-start">Giftcards</h5>
          </div>
          <div>
            <Link to="/cart" className="cart-icon">
              <MdOutlineShoppingCart />
              {cartProducts?.length > 0 && (
                <span className="badge badge-warning" id="lblCartCount">
                  {" "}
                  {cartProducts?.length}{" "}
                </span>
              )}
            </Link>
          </div>

          <Nav className="me-auto w-80">
            <Form className="d-flex position-relative search-form">
              <Form.Control
                type="search"
                placeholder="Search Giftcard"
                className="home-search"
                aria-label="Search"
              />
              <MdSearch className="search-icon" />
            </Form>
          </Nav>

          {!isLoggedIn && (
            <div className="desktop-view">
              <Button
                href="/login"
                className="me-5"
                variant="primary"
                size="sm"
              >
                Login/Register
              </Button>
            </div>
          )}
        </Container>
      </Navbar>
      <section>
        <div className="gift-details cart-ro pb-24">
          <Container>
            <Row>
              <Col className="desktop-view desk-filter" xs={12} md={3}>
                <h4>Brands</h4>
                {brands &&
                  brands.map((brand, i) => (
                    <div className="form-check" key={i}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={brand.name}
                        id="flexCheckDefault"
                        onClick={() => {
                          handleMultipleBrands(brand.name);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        {brand.name}
                      </label>
                    </div>
                  ))}

                {categories?.length > 0 && <h4>Categories</h4>}
                {categories &&
                  categories.map((category, i) => (
                    <div className="form-check" key={i}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        {category.name}
                      </label>
                    </div>
                  ))}
              </Col>
              <Col xs={12} md={9} className="desk-list mt-2">
                {products.length === 0 && isLoading && <Loader />}
                {products.length === 0 && !isLoading && (
                  <p className="text-center">No gift cards</p>
                )}

                {products && (
                  <div
                    style={{
                      height: "100vh",
                      overflow: "auto",
                    }}
                    id="content"
                  >
                    <InfiniteScroll
                      dataLength={products?.length}
                      pageStart={0}
                      next={loadMore}
                      hasMore={
                        products?.length >= limit &&
                        products?.length < totalGifts
                      }
                      loader={
                        <div
                          key={Math.random()}
                          style={{
                            width: "100%",
                            height: "100",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <ThreeDots
                            height="40"
                            width="40"
                            radius="9"
                            color="#EA5D00"
                            ariaLabel="three-dots-loading"
                            visible={true}
                          />
                        </div>
                      }
                      useWindow={false}
                      scrollableTarget="content"
                    >
                      <Row>
                        {products?.map((product, i) => (
                          <GiftCard
                            key={i}
                            name={product.name}
                            image={
                              product?.images?.custom || product?.images?.mobile
                            }
                            price={product?.price?.min}
                            discount={""}
                            productId={product._id}
                            expiry={product?.issuerData?.expiry}
                          />
                        ))}
                      </Row>
                    </InfiniteScroll>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <Modal
        show={show}
        onHide={handleClose}
        className="mobile-view full-model"
      >
        <Modal.Header closeButton>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row className="filter-details">
              <Col md={3}>
                <Nav variant="pills" className="flex-column">
                  {categories?.length > 0 && (
                    <Nav.Item>
                      <Nav.Link eventKey="first">Categories</Nav.Link>
                    </Nav.Item>
                  )}
                  <Nav.Item>
                    <Nav.Link eventKey="second">Brands</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col md={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    {categories &&
                      categories.map((category, i) => (
                        <div className="form-check" key={i}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            {category}
                          </label>
                        </div>
                      ))}
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    {brands &&
                      brands.map((brand, i) => (
                        <div className="form-check" key={i}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={brand.name}
                            onClick={() => {
                              handleMultipleBrands(brand.name);
                            }}
                            id="flexCheckDefault"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            {brand.name}
                          </label>
                        </div>
                      ))}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer className="modal-footer-action">
          <Button
            size="lg"
            variant="secondary"
            onClick={() => {
              setBrand("");
              handleClose();
            }}
          >
            Reset
          </Button>
          <Button
            size="lg"
            variant="primary"
            onClick={() => {
              fetchProducts();
              handleClose();
            }}
          >
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
      <Button
        className="mobile-view filter-btn"
        variant="primary"
        size="md"
        onClick={handleShow}
      >
        <MdOutlineFilterList /> Filters
      </Button>
      <CustomBottomNav />
    </>
  );
}

export default GiftCards;
