import React from "react";
import AddressUpdateModel from "./AddressUpdateModel";

const Address = (props) => {
  const addressId = props.addressId;
  const firstName = props.firstName;
  const lastName = props.lastName;
  const addressType = props.addressType;
  const address1 = props.address1;
  const address2 = props.address2;
  const landMark = props.landMark;
  const city = props.city;
  const state = props.state;
  const country = props.country;
  const pinCode = props.pinCode;
  const phoneCountryCode = props.phoneCountryCode;
  const phoneNumber = props.phoneNumber;
  const isDefault = props.isDefault;
  const onNewAddressAdded = props.onNewAddressAdded;

  return (
    <div className="form-check">
      <input
        id="credit"
        name="billingAddress"
        type="radio"
        className="form-check-input"
        defaultChecked={isDefault}
        value={addressId}
        onChange={(event) => {
          // console.log(event);
          // console.log(event.target.checked);
          // console.log(addressId);
          props?.handleInput(event);
        }}
        required
      />
      <label className="address-label">
        <h6>
          {firstName} {lastName} <span className="status">{addressType}</span>
          {isDefault && <span className="status">DEFAULT</span>}
          {/* <Link className="edit">Edit</Link>{" "} */}
          <AddressUpdateModel
            key={addressId}
            addressId={addressId}
            firstName={firstName}
            lastName={lastName}
            addressType={addressType}
            address1={address1}
            address2={address2}
            landMark={landMark}
            city={city}
            state={state}
            country={country}
            pinCode={pinCode}
            phoneCountryCode={phoneCountryCode}
            phoneNumber={phoneNumber}
            isDefault={isDefault}
            onNewAddressAdded={onNewAddressAdded}
          />
        </h6>
        <small>
          {address1} {address2} {landMark} {city} {state} {country} - {pinCode},{" "}
          {phoneCountryCode}
          {phoneNumber}
        </small>
      </label>
    </div>
  );
};

Address.propTypes = {};

export default Address;
