import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Form } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { MdOutlineFilterList, MdSearch } from "react-icons/md";
import InfiniteScroll from "react-infinite-scroll-component";

import CustomNavBar from "../CustomNavBar";
import OrderProduct from "./OrderProduct";

import { getOrders } from "../../store/orderSlice";
import Loader from "../loader/Loader";
import APIService from "../../services/api.service";
import { ThreeDots } from "react-loader-spinner";

function MyOrders() {
  // infinite scrolling
  const [page, setPage] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [limit, setLimit] = useState(20);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const searchRef = useRef("");

  const loadMore = () => {
    // console.log(page, orders.length);
    const p = page + 1;
    var url = "/api/v1/order?page=" + p + "&limit=" + limit;
    if (
      searchRef?.current?.value &&
      searchRef?.current?.value !== 0 &&
      searchRef?.current?.value?.length > 3
    )
      url = url + "&search=" + searchRef?.current?.value;

    APIService.sendRequest(url).then((response) => {
      if (response?.success) {
        if (response?.orders?.length > 0) {
          setOrders((p) => [...p, ...response.orders]);
          setTotalOrders(response?.total);
          setPage((page) => page + 1);
        }
      }
    });
  };

  const fetchProducts = () => {
    setIsLoading(true);
    // console.log(page, orders.length);
    const p = 1;
    var url = "/api/v1/order?page=" + p + "&limit=" + limit;
    if (
      searchRef?.current?.value &&
      searchRef?.current?.value !== 0 &&
      searchRef?.current?.value?.length > 3
    )
      url = url + "&search=" + searchRef?.current?.value;

    APIService.sendRequest(url)
      .then((response) => {
        if (response?.success) {
          if (response?.orders?.length > 0) {
            setOrders(response?.orders);
            setTotalOrders(response?.total);
            setPage(1);
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // const handleMultipleBrands = (b) => {
  //   console.log(b, brand);
  //   if (brand && brand !== "") {
  //     setBrand(brand + "," + b);
  //   } else {
  //     setBrand(b);
  //   }
  // };

  useEffect(() => {
    fetchProducts();
  }, []);

  // useEffect(() => {
  //   dispatch(getOrders());
  // }, [dispatch]);

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(event);
    // console.log(searchRef.current.value);
    fetchProducts();
  };

  return (
    <>
      <CustomNavBar title={"My Orders"} to="/" />

      <section>
        <div className="gift-details cart-ro pb-24">
          <div className="order-subheader container">
            <div>
              <Form
                className="d-flex position-relative"
                onSubmit={handleSubmit}
              >
                <Form.Control
                  type="search"
                  placeholder="Search by name, order ID"
                  className="home-search"
                  aria-label="Search"
                  ref={searchRef}
                />
                <MdSearch className="search-icon" />
              </Form>
            </div>
            <div>
              <Button variant="light" size="md">
                <MdOutlineFilterList /> Filters
              </Button>
            </div>
          </div>
          <Container>
            {orders.length === 0 && isLoading && <Loader />}
            {orders.length === 0 && !isLoading && (
              <p className="text-center">No Orders</p>
            )}

            {orders && (
              <div
                style={{
                  height: "100vh",
                  overflow: "auto",
                }}
                id="content"
              >
                <InfiniteScroll
                  dataLength={orders?.length}
                  pageStart={0}
                  next={loadMore}
                  hasMore={
                    orders?.length >= limit && orders?.length < totalOrders
                  }
                  loader={
                    <div
                      key={Math.random()}
                      style={{
                        width: "100%",
                        height: "100",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ThreeDots
                        height="40"
                        width="40"
                        radius="9"
                        color="#EA5D00"
                        ariaLabel="three-dots-loading"
                        visible={true}
                      />
                    </div>
                  }
                  useWindow={false}
                  scrollableTarget="content"
                  endMessage={
                    orders?.length > 6 && (
                      <p className="text-center">
                        You have reached the end of Your Orders
                      </p>
                    )
                  }
                >
                  {orders?.map((order, i) => (
                    <OrderProduct
                      key={i}
                      image={
                        order?.products[0]?.product?.images?.custom ||
                        order?.products[0]?.product?.images?.mobile
                      }
                      name={order?.products[0]?.product.name}
                      orderId={order?.orderId}
                      price={order?.orderTotal}
                      date={order?.createdAt?.slice(0, 10)}
                      expiry={order?.woohooCard?.cards[0].validity?.slice(
                        0,
                        10
                      )}
                      statusLabel={order?.statusLabel}
                    />
                  ))}
                </InfiniteScroll>
              </div>
            )}
          </Container>
        </div>
      </section>
    </>
  );
}

export default MyOrders;
