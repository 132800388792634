import { Accordion } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import CustomNavBar from "./CustomNavBar";

function Help() {
  return (
    <>
      <CustomNavBar title={"Help & Support"} />
      <section className="profile-summary-wrapper">
        <div className="desktop-wrapper">
          <Container className="cart-wrapper">
            <h4>FAQ's</h4>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>How can I use my voucher?</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Not only are our vouchers delivered instantly,they are also
                    very easy to redeem. A voucher that can be used at a store
                    simply needs to be presented at the cash counter at the time
                    of billing. The voucher amount is adjusted and the remaining
                    amount of the bill, if any, will need to be paid in
                    cash/card. Please note that these are one-time use vouchers.
                    To make the most of it, try using vouchers if the billing
                    value is equal to or more than the voucher amount.
                  </p>
                  <p>
                    For online redemptions, please visit the brand page on
                    www.gyftr.com and check the steps of redemption.
                  </p>
                  <p>
                    We also have some videos of major brands on our YouTube
                    page. Please visit bit.ly/3kPXSJs and subscribe to our
                    channel and get to see the easy-to-understand video
                    tutorials of various brands. We ensure that up-to-date
                    videos are made available for your reference.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  What is the Validity of the Gift Vouchers?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    The validity of each Gift Voucher varies from brand to brand
                    and can be between 3 months to 12 months. For the exact
                    validity, please refer to your Voucher Email or SMS.
                  </p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  I have lost my voucher SMS/email. What should I do?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Simply log on to www.gvhelpdesk.com and enter your voucher
                    number in the{" "}
                  </p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  I want to return/cancel a voucher I purchased.
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    The validity of each Gift Voucher varies from brand to brand
                    and can be between 3 months to 12 months. For the exact
                    validity, please refer to your Voucher Email or SMS.
                  </p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  Where can I locate the outlet?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    The validity of each Gift Voucher varies from brand to brand
                    and can be between 3 months to 12 months. For the exact
                    validity, please refer to your Voucher Email or SMS.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  I am at the store and the cashier is not accepting the
                  voucher.
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    The validity of each Gift Voucher varies from brand to brand
                    and can be between 3 months to 12 months. For the exact
                    validity, please refer to your Voucher Email or SMS.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  I have lost my voucher SMS/email. What should I do?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Simply log on to www.gvhelpdesk.com and enter your voucher
                    number in the{" "}
                  </p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  I want to return/cancel a voucher I purchased.
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    The validity of each Gift Voucher varies from brand to brand
                    and can be between 3 months to 12 months. For the exact
                    validity, please refer to your Voucher Email or SMS.
                  </p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  Where can I locate the outlet?
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    The validity of each Gift Voucher varies from brand to brand
                    and can be between 3 months to 12 months. For the exact
                    validity, please refer to your Voucher Email or SMS.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10">
                <Accordion.Header>
                  I am at the store and the cashier is not accepting the
                  voucher.
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    The validity of each Gift Voucher varies from brand to brand
                    and can be between 3 months to 12 months. For the exact
                    validity, please refer to your Voucher Email or SMS.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Container>
        </div>
      </section>
    </>
  );
}

export default Help;
