import React from "react";
import SearchItem from "./SearchItem";

const SearchList = (props) => {
  const filteredProducts = props.filteredProducts;
  const filtered = filteredProducts.map((product) => (
    <SearchItem product={product} key={product._id} />
  ));
  return <>{filtered}</>;
};

export default SearchList;
