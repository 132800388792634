import React from "react";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
import CustomNavBar from "./CustomNavBar";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Sevenpay</title>
      </Helmet>
      <CustomNavBar title={"Privacy Policy"} />
      <section className="profile-summary-wrapper">
        <div className="desktop-wrapper">
          <Container className="cart-wrapper">
            <h2>Privacy Policy</h2>
            <p>
              <em>Effective Date: December, 13, 2021</em>
            </p>
            <p>
              <strong>Commitment</strong>
            </p>
            <p>
              At sevenpay.in, we are committed to protecting the privacy of our
              users (whether or not registered with sevenpay.in) on our website
              located at www.sevenpay.in ("our Website") and the privacy of
              their data provided to us from time to time. Your privacy is
              essential to us, and we are dedicated to keeping your personal and
              other information secure. We encourage you to read this privacy
              statement to understand what types of personally identifiable
              information we collect and how we use this information.
            </p>

            <br />
            <h4>
              <strong>What Information We Collect</strong>
            </h4>
            <p>
              <strong>Personal Information:</strong> To provide you with quality
              services, we require you to enroll on our website and get yourself
              registered. If you choose to become a registered member of our
              website, we will collect your name, email address, a unique login
              name, password, password validation, and country ("Personal
              Information").
            </p>
            <p>
              <strong>User Information:</strong> In addition to Personal
              Information, you may also choose to share other personal details,
              such as gender, age, postal address, phone number, date of birth,
              job role, occupation, opinion on deals available, feedback,
              suggestions, views, survey information, etc. ("User Information").
              Please note that any User Information shared by you on our website
              may be accessible to all users and visitors. Exercise discretion
              before making your personal details public, as this information is
              vulnerable to misuse.
            </p>
            <p>
              <strong>Non-Personal Information:</strong> We also collect certain
              non-personal information about your visit to our website, such as
              your operating system, browser type, URL of the previous website
              visited, internet service provider, and IP Address ("Non-Personal
              Information"). This information cannot be used to personally
              identify you. We use this information for various purposes,
              including analyzing trends, troubleshooting connection problems,
              gathering demographic information, and improving the site's
              content and performance.
            </p>
            <p>
              <strong>Cookies:</strong> Our website utilizes cookies, which are
              small pieces of information stored by your browser on your
              computer's hard drive. Cookies allow us to recognize your computer
              each time you return to our website and provide features such as
              remembering your login information and suggesting relevant
              products or services. You can control how cookies are accepted by
              your browser, but please note that disabling cookies may affect
              certain features on our website.
            </p>

            <br />
            <h4>
              <strong>How We Use Your Information</strong>
            </h4>

            <p>
              We use your Personal Information and User Information to provide
              you with the content and services you request, send you updates,
              discounts, and deals, and keep you informed about the latest
              trending deals and offers. We do not trade, sell, or deal in your
              Personal Information, except as specified in this policy or with
              your express consent.
            </p>
            <p>
              Additionally, we may use the Non-Personal Information collected to
              analyze website usage, evaluate your use of our products/services,
              improve our website content, and measure the effectiveness of our
              online advertising.
            </p>
            <p>
              <strong>Third-Party Websites</strong>
            </p>
            <p>
              Our Privacy Policy applies exclusively to sevenpay.in, and we are
              not responsible for the privacy policies of third-party websites
              linked from our website. Please review the privacy policy of any
              third-party website you visit before using it.
            </p>
            <p>
              <strong>Children's Privacy</strong>
            </p>
            <p>
              Users must be at least 18 years old to use our website. If you are
              under 18 years of age, you are strictly prohibited from
              registering on our website or providing any personal information.
              If you believe that your child has disclosed personally
              identifiable information to us, please contact us immediately, and
              we will take steps to delete the information.
            </p>
            <p>
              <strong>Changes to Privacy Policy </strong>
            </p>
            <p>
              We may change this Privacy Policy from time to time without your
              consent. The revised policy will be effective immediately upon
              posting on our website. It is your responsibility to check the
              Privacy Policy periodically.
            </p>
            <p>
              <strong>Contact Us</strong>
            </p>
            <p>
              If you have any questions about this Privacy Policy or your
              dealings with our website, please contact us at
              support@sevenpay.in.
            </p>
          </Container>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
