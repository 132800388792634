import React from "react";
import { Link } from "react-router-dom";

import { ListGroup, Offcanvas } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import {
  MdLogout,
  MdOutlineLiveHelp,
  MdOutlinePermIdentity,
  MdOutlineShoppingBag
} from "react-icons/md";

const Drawer = (props) => {
  const show = props.show;
  const handleClose = props.handleClose;
  const name = props.name || "";
  return (
    <Offcanvas show={show} onHide={handleClose}>
      <Offcanvas.Body className="p-0">
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Container>
            <div>
              <h5 className="text-white m-2">Hi {name}!</h5>
              <p className="text-white m-2">Manage your profile and orders</p>
            </div>

            <Nav>
              <Nav.Link eventKey={2} href="#memes"></Nav.Link>
            </Nav>
          </Container>
        </Navbar>
        <section>
          <ListGroup>
            <Link to="/myorders">
              <ListGroup.Item>
                <MdOutlineShoppingBag />
                <h6>My Orders</h6>
                <p>View all your bookings & purchases</p>
              </ListGroup.Item>
            </Link>
            <Link to="/profiledetails">
              <ListGroup.Item>
                <MdOutlinePermIdentity />
                <h6>Profile Details</h6>
                <p>Profile, Payments, Permissions & More</p>
              </ListGroup.Item>
            </Link>
            <Link to="/help">
              <ListGroup.Item>
                <MdOutlineLiveHelp />
                <h6>Help & Support</h6>
                <p>View commonly asked queries and Chat</p>
              </ListGroup.Item>
            </Link>
            <Link to="/about">
              <ListGroup.Item>
                <MdOutlineLiveHelp />
                <h6>About Sevenpay</h6>
                <p>Contact us, Privacy Policy, Refund</p>
              </ListGroup.Item>
            </Link>

            <Link to="/logout">
              <ListGroup.Item>
                <MdLogout />
                <h6>Logout</h6>
                <p>View all your bookings & purchases</p>
              </ListGroup.Item>
            </Link>
          </ListGroup>
        </section>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default Drawer;
