import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import APIService from "../../services/api.service";
import { getOffers } from "../../store/offerSlice";

const PopularBrands = () => {
  const dispatch = useDispatch();

  const [brands, setBrands] = useState([]);

  useEffect(() => {
    APIService.sendRequest("/api/v1/products/brands").then((response) => {
      setBrands(response?.brands);
      // console.log(response);
    });

    dispatch(getOffers());
  }, []);

  return (
    <section className="scroll-hidden mb-5 mt-4">
      <div className="container">
        {/* <h4 className="ms-3"> */}
        <h4>
          Popular Brands <Link to={"/brandlist?type=popular"}>View All</Link>{" "}
        </h4>
        <div className="horizontal-scroll d-flex card-scroll">
          {brands &&
            brands.map((brand, i) => (
              <Link to={"/giftcards?brand=" + brand.name} key={i}>
                <img
                  width={190}
                  // height={190}
                  src={brand?.images?.mobile || "../img/flipkart.svg"}
                  alt="brand"
                ></img>
              </Link>
            ))}
        </div>
      </div>
    </section>
  );
};

export default PopularBrands;
