import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import APIService from "../../services/api.service";
import CustomNavBar from "../CustomNavBar";
import Loader from "../loader/Loader";
import OrderSuccess from "./OrderSuccess";
import OrderFailed from "./OrderFailed";
import OrderCancel from "./OrderCancel";
import { useSelector } from "react-redux";
import { Button, Container } from "react-bootstrap";

function OrderStatus() {
  // TODO: allow only referrer from backend
  const [order, setOrder] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  var referrer = document.referrer || document.referer;

  // const { isLoggedIn } = useSelector((state) => state.user);
  const isLoggedIn = false;
  // console.log("isLoggedIn", isLoggedIn);

  const params = useParams();
  // console.log("referrer", referrer);
  // console.log("params", params);
  // console.log(
  //   "location",
  //   location?.pathname,
  //   location?.pathname?.includes("failure")
  // );

  useEffect(() => {
    setIsLoading(true);

    if (isLoggedIn)
      APIService.sendRequest("/api/v1/order/" + params.orderId)
        .then((response) => {
          // console.log("response", response);
          setOrder(response.order);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    else {
      setIsLoading(false);
    }
  }, []);

  return (
    <>
      <CustomNavBar title={"Order Status"} to={"/myorders"} />
      <section>
        {isLoading && <Loader />}
        {!isLoading && (
          <div className="gift-details cart-ro pb-24 pt-24">
            <section>
              <div className="desktop-wrapper">
                {location?.pathname?.includes("success") && isLoggedIn && (
                  <OrderSuccess
                    orderId={order?.orderId}
                    date={order?.createdAt}
                  />
                )}
                {location?.pathname?.includes("success") && !isLoggedIn && (
                  <OrderSuccess
                    orderId={params.orderId}
                    date={new Date().toLocaleString()}
                  />
                )}
                {location?.pathname?.includes("failure") && isLoggedIn && (
                  <OrderFailed
                    orderId={order?.orderId}
                    date={order?.createdAt}
                  />
                )}
                {location?.pathname?.includes("failure") && !isLoggedIn && (
                  <OrderFailed
                    orderId={params.orderId}
                    date={new Date().toLocaleString()}
                  />
                )}
                {location?.pathname?.includes("cancel") && isLoggedIn && (
                  <OrderCancel
                    orderId={order?.orderId}
                    date={order?.createdAt}
                  />
                )}
                {location?.pathname?.includes("cancel") && !isLoggedIn && (
                  <OrderCancel
                    orderId={params.orderId}
                    date={new Date().toLocaleString()}
                  />
                )}
              </div>
            </section>
          </div>
        )}
      </section>
    </>
  );
}

export default OrderStatus;
