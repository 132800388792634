import React from "react";

const OrderGiftCardDetails = (props) => {
  // const product = props.product;
  // console.log("props?.orderItem", props?.orderItem?.woohooCard?.cards[0]);
  if (props?.orderItem?.woohooCard) {
    var recard = props?.orderItem?.woohooCard?.cards[0];
    // var recard = props?.orderItem?.woohooCard?.cards?.filter((card) => {
    // console.log("card");
    // console.log(card);
    // console.log(product);
    // console.log(card.sku);
    // console.log(product.product.sku);
    //   return card.sku === product.product.sku;
    // });

    // console.log("result card");
    // console.log(recard);

    if (recard?.cardNumber && recard?.cardPin && recard?.activationUrl)
      return (
        <div className="final-giftcard">
          <p>Voucher Code: {recard?.cardNumber}</p>
          <p>PIN: {recard?.cardPin}</p>
          <p>
            Claim Coupan At:{" "}
            <a href={recard?.activationUrl} target="_blank" rel="noreferrer">
              Click here
            </a>
          </p>
        </div>
      );
    if (recard?.cardNumber && recard?.cardPin) {
      if (recard?.cardPin.length === 16) {
        return (
          <div className="final-giftcard">
            <p>Voucher Code: {recard?.cardNumber}</p>
            <p>PIN: {recard?.cardPin} (Only PIN is required to redeem)</p>
          </div>
        );
      }
      return (
        <div className="final-giftcard">
          <p>Voucher Code: {recard?.cardNumber}</p>
          <p>PIN: {recard?.cardPin}</p>
        </div>
      );
    }
    if (!recard?.cardPin)
      return (
        <div className="final-giftcard">
          <p>Voucher Code: {recard?.cardNumber}</p>
          <p>PIN: No pin required</p>
        </div>
      );
    if (!recard?.cardNumber)
      return (
        <div className="final-giftcard">
          <p>Voucher Code: Not required</p>
          <p>PIN: {recard?.cardPin} (Only PIN is required to redeem)</p>
        </div>
      );
  }
  return (
    <div>
      <i>Note: </i>
      <span>
        The gift card details will be sent via mail shortly and will also be
        accessible on this platform.
      </span>
    </div>
  );
};

export default OrderGiftCardDetails;
