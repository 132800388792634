import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

const CustomProductCard = (props) => {
  const image = props.image;
  const name = props.name;
  const discount = props.discount || 0;
  const productId = props.productId;

  return (
    <Link to={"/giftcards/" + productId}>
      <Card className="custom-card" style={{ width: "15rem" }}>
        <Card.Img className="gift-img" variant="top" src={image} alt="customproductimage"/>
        <img className="shadow-img" src="../img/shadow.png" alt="Shadow" />
        <Card.Body>
          <Card.Title title={name}>
            {name?.length > 20 ? name.slice(0, 20) + "..." : name}
          </Card.Title>
          <Card.Text>{discount}% off</Card.Text>
          <Button variant="primary">Buy Now</Button>
        </Card.Body>
      </Card>
    </Link>
  );
};

export default CustomProductCard;
