import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { MdArrowBack, MdCheckCircle } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../loader/Loader";
import APIService from "../../services/api.service";
import { Button } from "react-bootstrap";

const EmailVerificationLinkSent = () => {
  const navigate = useNavigate();
  const [emailSent, setEmailSent] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    APIService.sendRequest("/auth/send/emailVerification").then((response) => {
      // console.log(response);
      if (response.success) {
        setEmailSent(true);
        setLoading(false);
      } else {
        setIsError(true);
        setErrorMessage(response.errorMessage);
        setLoading(false);
      }
    });
  }, []);

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container>
          <div>
            <Link to="/profiledetails" className="back-icon">
              <MdArrowBack />
            </Link>
            <h5 className="text-white m-3 float-start">Email Verification</h5>
          </div>
        </Container>
      </Navbar>

      {isLoading && <Loader />}
      {emailSent && (
        <section>
          <div className="desktop-wrapper mt-5">
            <Container className="cart-wrapper">
              <div className="details-card text-center">
                <img
                  src="../../img/success.svg"
                  className="success-img"
                  alt="successimg"
                />
                <h3 className="green-text">Email Sent!</h3>
                <p>You have been sent email verification link.</p>
                <Button
                  variant="primary"
                  size="lg"
                  className="mt-3"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Home
                </Button>
              </div>
            </Container>
          </div>
        </section>
      )}
      {isError && (
        <section>
          <div className="desktop-wrapper mt-5">
            <Container className="cart-wrapper">
              <div className="details-card text-center">
                <img
                  src="../../img/success.svg"
                  className="success-img"
                  alt="success-img"
                />
                <h3 className="green-text">Email Verified!</h3>
                <p>Your email had been verified already</p>
                <Button variant="primary" size="lg" className="mt-3">
                  Login
                </Button>
              </div>
            </Container>
          </div>
        </section>
      )}
    </>
  );
};

export default EmailVerificationLinkSent;
