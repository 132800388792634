import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Container from "react-bootstrap/Container";

import { Button, Col, Form, Row } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { MdArrowBack } from "react-icons/md";

import { signUpUser, userActions } from "../../store/userSlice";

function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isSignupError, signUpErrorMessage, isSignUpSuccess } = useSelector(
    (state) => state.user
  );

  const [auth, setAuth] = useState({
    email: "",
    phone: "",
    name: "",
    password: "",
    confirmPassword: "",
  });

  function handleInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    setAuth({
      ...auth,
      [name]: value,
    });
  }

  function handleSubmit(e) {
    dispatch(userActions.setLoginError({ success: true, errorMessage: "" }));
    e.preventDefault();
    e.stopPropagation();
    dispatch(signUpUser(auth));
  }

  useEffect(() => {
    if (isSignUpSuccess) {
      // send mail and redirect to mail sent page
      navigate("/register/request-to-email-verify");
    }
  }, [isSignUpSuccess, navigate]);

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container>
          <div>
            <Link to="/" className="back-icon">
              <MdArrowBack />
            </Link>
            <h5 className="text-white m-3 float-start">Signup</h5>
          </div>
        </Container>
      </Navbar>
      <section>
        <Container className="login-wrapper mb-5">
          <Row>
            <Col className="left">
              <h1>Get Started</h1>
              <h4>Fill your details to get started</h4>
              <img src="../img/banner.svg" alt="banner" />
            </Col>
            <Col className="right">
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    defaultValue={auth.email}
                    onChange={handleInput}
                    name="email"
                  />
                </Form.Group>

                {/* <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={auth.firstName}
                    onChange={handleInput}
                    name="firstName"
                  />
                </Form.Group> */}
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={auth.name}
                    onChange={handleInput}
                    name="name"
                    placeholder="Enter name"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={auth.phone}
                    onChange={handleInput}
                    name="phone"
                    placeholder="Enter phone number"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    defaultValue={auth.password}
                    onChange={handleInput}
                    name="password"
                    placeholder="Enter password"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="formBasicConfirmPassword"
                >
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    defaultValue={auth.confirmPassword}
                    onChange={handleInput}
                    name="confirmPassword"
                    placeholder="Enter confirm password"
                    isInvalid={isSignupError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {signUpErrorMessage}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button
                  variant="primary"
                  size="lg"
                  className="w-100 mt-3"
                  type="submit"
                >
                  Sign Up
                </Button>
              </Form>

              <div className="text-center mt-3">
                <p>
                  Already have account?{" "}
                  <Link className="" to="/login">
                    Login
                  </Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default SignUp;
