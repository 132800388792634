import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Alert, Button, Col, Form, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { isBrowser } from "react-device-detect";
import { MdArrowBack, MdGppGood } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import APIService from "../../services/api.service";
import { getAddresses, getCartProducts } from "../../store/cartSlice";
import { createOrder } from "../../store/orderSlice";
import Address from "./Address";
import AddressAddModel from "./AddressAddModel";
import CheckoutItem from "./CheckoutItem";
import PayuPaymentGateway from "./PayuPaymentGateway";
import PayuPaymentGatewayInPage from "./PayuPaymentGatewayInPage";
import SBIPaymentGateway from "./SBIPaymentGateway";
import PhonepePaymentGateway from "./PhonepePaymentGateway";

function Checkout() {
  const { products, total, finalTotal, totalDiscount, addresses } = useSelector(
    (state) => state.cart
  );

  const { gateway, orderId, success, errorMessage } = useSelector(
    (state) => state.order
  );

  console.log(gateway, orderId, success, errorMessage);
  console.log("isBrowser", isBrowser);

  const { user } = useSelector((state) => state.user);
  // console.log(user?.email);
  const [step, setStep] = useState("0");
  const [checkouts, setCheckouts] = useState({
    billingAddress: "",
    recipientEmail: user?.email || "",
  });

  //const currentPaymentGateway = "razorpay"; //sbi
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchCartItems = async () => {
    dispatch(getCartProducts());
  };

  const fetchAddresses = () => {
    dispatch(getAddresses());
  };

  const onNewAddressAdded = async () => {
    fetchAddresses();
  };

  const showNotifications = (msg) => {
    if (msg?.length > 0)
      toast(msg, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // className: "toaster_custom_color",
        // bodyClassName: "toaster_custom_body_color",
      });
  };

  useEffect(() => {
    if (!success) showNotifications(errorMessage);
  }, [success, errorMessage]);

  useEffect(() => {
    dispatch(getCartProducts());
    dispatch(getAddresses());
  }, [dispatch]);

  useEffect(() => {
    const defaultAddress = addresses?.filter(
      (address) => address.isDefault === true
    );
    var defaultAddressId = "";
    if (defaultAddress?.length > 0) {
      defaultAddressId = defaultAddress[0].addressId;
    }
    // console.log("defaultAddress", defaultAddress, defaultAddressId);
    if (defaultAddressId && defaultAddressId !== "") {
      setCheckouts({
        ...checkouts,
        billingAddress: defaultAddressId,
      });
    }
  }, [addresses]);

  function handleInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    // console.log(name, value);
    setCheckouts({
      ...checkouts,
      [name]: value,
    });
  }

  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  async function handleSubmit(e) {
    e.preventDefault();
    if (!checkouts.billingAddress) {
      // console.log("Billing address required");
      setStep("0");
      return;
    }
    if (!checkouts.recipientEmail) {
      // console.log("Email required");
      setStep("1");
      return;
    }

    if (!isEmail(checkouts.recipientEmail)) {
      // console.log("Email required");
      setStep("1");
      return;
    }

    // console.log(checkouts);
    dispatch(createOrder(checkouts));
  }

  const handleRemoveCartProduct = (data) => {
    APIService.sendRequest("/api/v1/cart", data, "DELETE").then((response) => {
      dispatch(getCartProducts());
    });
  };

  const handleUpdateCartProduct = (data) => {
    APIService.sendRequest("/api/v1/cart", data, "PATCH").then((response) => {
      // console.log("update", response);
      dispatch(getCartProducts());
    });
  };

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
          <div>
            <a href="/" className="back-icon">
              <MdArrowBack />
            </a>
            <h5 className="text-white m-3 float-start">Checkout</h5>
          </div>
        </Container>
      </Navbar>
      <section>
        <div className="gift-details cart-row">
          <Container>
            <Row>
              <Col xs={12} md={8} className="payment-cart">
                <Accordion defaultActiveKey="0" activeKey={step}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header
                      onClick={() => {
                        setStep("0");
                      }}
                    >
                      <span>1</span>Billing Address
                    </Accordion.Header>
                    <Accordion.Body>
                      {addresses?.map((address, i) => (
                        <Address
                          key={address.addressId}
                          addressId={address.addressId}
                          firstName={address.firstName}
                          lastName={address.lastName}
                          addressType={address.addressType}
                          address1={address.address1}
                          address2={address.address2}
                          landMark={address.landMark}
                          city={address.city}
                          state={address.state}
                          country={address.country}
                          pinCode={address.pinCode}
                          phoneCountryCode={address.phoneCountryCode}
                          phoneNumber={address.phoneNumber}
                          isDefault={address.isDefault}
                          handleInput={handleInput}
                          onNewAddressAdded={onNewAddressAdded}
                        />
                      ))}

                      <AddressAddModel onNewAddressAdded={onNewAddressAdded} />

                      <div className="text-end">
                        <Button
                          variant="primary mb-1"
                          size="md"
                          onClick={() => {
                            // console.log("ddff1111");
                            // console.log(step);
                            setStep("1");
                          }}
                        >
                          Next
                        </Button>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header
                      onClick={() => {
                        setStep("1");
                      }}
                    >
                      <span>2</span>Shipping Method
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Gift voucher details will be sent to the following email
                      </p>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          name="recipientEmail"
                          // value={user?.email}
                          defaultValue={checkouts?.recipientEmail}
                          onChange={handleInput}
                        />
                      </Form.Group>
                      <div className="text-end">
                        <Button
                          variant="primary mb-1"
                          size="md"
                          onClick={() => {
                            setStep("2");
                          }}
                        >
                          Next
                        </Button>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header
                      onClick={() => {
                        setStep("2");
                      }}
                    >
                      <span>3</span>Order Overview
                    </Accordion.Header>
                    <Accordion.Body>
                      {products?.map((product, i) => (
                        <CheckoutItem
                          key={i}
                          productId={product.productId}
                          name={product.product.name}
                          image={
                            product?.product?.images?.custom ||
                            product?.product?.images?.mobile
                          }
                          discount={product?.discount}
                          price={product.price}
                          discountPrice={product.subtotal}
                          quantity={product.quantity}
                          handleRemoveCartProduct={handleRemoveCartProduct}
                          handleUpdateCartProduct={handleUpdateCartProduct}
                        />
                      ))}
                      {products?.length > 0 && (
                        <div className="text-end mt-4">
                          <Button
                            variant="primary mb-1"
                            size="md"
                            onClick={handleSubmit}
                          >
                            Continue to Payment
                          </Button>
                        </div>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
              <Col xs={12} md={4}>
                <div className="price-details">
                  <label>Price Details</label>
                  <h4>
                    Price ({products?.length} items) <span>₹{finalTotal}</span>
                  </h4>
                  <h4>
                    Discount <span>₹{totalDiscount}</span>
                  </h4>
                  <h4 className="total">
                    Total Amount <span>₹{total}</span>
                  </h4>
                  {totalDiscount > 0 && (
                    <Alert variant="success">
                      Hurrey!
                      <p>You got ₹{totalDiscount} OFF on cards</p>
                    </Alert>
                  )}
                </div>
                <div className="safe">
                  <MdGppGood /> Safe and Secure Payments.
                </div>
              </Col>
            </Row>
            <div className="details-card"></div>
          </Container>
          {gateway?.name === "payu" && isBrowser && (
            <PayuPaymentGateway gateway={gateway?.data} />
            // <PayuPaymentGatewayInPage gateway={gateway?.data} />
          )}
          {gateway?.name === "payu" && !isBrowser && (
            // <PayuPaymentGateway gateway={gateway?.data} />
            <PayuPaymentGatewayInPage gateway={gateway?.data} />
          )}
          {/*TODO: resolve mobile browser issue */}
          {gateway?.name === "sbi" && isBrowser && (
            // <PayuPaymentGateway gateway={gateway?.data} />
            <SBIPaymentGateway gateway={gateway?.data} />
          )}
          {gateway?.name === "phonepe" && isBrowser && (
            // <PayuPaymentGateway gateway={gateway?.data} />
            <PhonepePaymentGateway gateway={gateway?.data} />
          )}
        </div>
        <ToastContainer />
      </section>
    </>
  );
}

export default Checkout;
