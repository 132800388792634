import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { MdArrowBack, MdOutlineShoppingCart, MdSearch } from "react-icons/md";
import { Link } from "react-router-dom";
import APIService from "../services/api.service";
import Header from "./home/Header";
import Loader from "./loader/Loader";

function BrandList() {
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    APIService.sendRequest("/api/v1/products/brands").then((response) => {
      setBrands(response.brands);
      // console.log(response);
    });
  }, []);

  return (
    <>
      {/* <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container className="position-relative">
          <div>
            <a href="/" className="back-icon">
              <MdArrowBack />
            </a>
            <h5 className="text-white m-3 float-start">Brands</h5>
          </div>
          <div>
            <a href="/cart" className="cart-icon">
              <MdOutlineShoppingCart />
            </a>
          </div>

          <Nav className="me-auto w-80">
            <Form className="d-flex position-relative search-form">
              <Form.Control
                type="search"
                placeholder="Search Giftcard"
                className="home-search"
                aria-label="Search"
              />
              <MdSearch className="search-icon" />
            </Form>
          </Nav>

          <div className="desktop-view">
            <Button className="me-5" variant="primary" size="sm">
              Login/Register
            </Button>
          </div>
        </Container>
      </Navbar>
       */}
      <Header
        title={
          <>
            <a href="/" className="back-icon">
              <MdArrowBack />
            </a>
            <h5 className="text-white m-3 float-start">Brands</h5>
          </>
        }
      />
      <section>
        <div className="gift-details cart-row pt-24 pb-24">
          <div className="container">
            {(!brands || brands.length === 0) && <Loader />}
            {brands?.map((brand, i) => (
              <Link
                key={i}
                to={"/giftcards?brand=" + brand.name}
                className="giftcard-link"
              >
                <div className="listing-card d-flex">
                  <Card.Img
                    className="gift-img"
                    variant="top"
                    src={brand?.images?.mobile}
                    alt="brand_image"
                  />
                  <Card.Body>
                    <Card.Title>{brand.name}</Card.Title>
                    {/* <Card.Text>
                      From: ₹25000 <br />
                      <small>Expiry: 3 months from date of activation.</small>
                    </Card.Text> */}
                  </Card.Body>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default BrandList;
