import React, { useState } from "react";
import { MdAdd, MdRemove, MdStar } from "react-icons/md";
import { Link } from "react-router-dom";

const CheckoutItem = (props) => {
  const productId = props.productId;
  const name = props.name;
  const image = props.image;
  const discount = props.discount;
  const price = props.price;
  const [quantity, setQuantity] = useState(props.quantity);
  const handleRemoveCartProduct = props.handleRemoveCartProduct;
  const handleUpdateCartProduct = props.handleUpdateCartProduct;

  const handleQuantity = (event) => {
    const value = event.target.value;
    if (Number(value) <= 0) setQuantity(1);
    else setQuantity(Number(value));
    handleUpdateCartProduct({ productId: productId, quantity: quantity });
  };

  const handleQuantityDecrement = () => {
    if (quantity <= 1) {
      // TODO: remove handleUpdateCartProduct call for quantity 1
      handleUpdateCartProduct({ productId: productId, quantity: 1 });
      setQuantity(1);
    } else {
      handleUpdateCartProduct({ productId: productId, quantity: quantity - 1 });
      setQuantity(quantity - 1);
    }
  };

  const handleQuantityIncrement = () => {
    handleUpdateCartProduct({ productId: productId, quantity: quantity + 1 });
    setQuantity(quantity + 1);
  };

  return (
    <div className="d-flex details-first ">
      <div className="text-start">
        <img className="mb-2 w-100" src={image} alt="productlogo" width={190} />
        <br />
      </div>
      <div className="w-75 details-text">
        <h4 className="">{name}</h4>

        <MdStar />
        <MdStar />
        <MdStar />
        <MdStar />
        {discount > 0 && <h5 className="tags discount">₹{discount} OFF</h5>}
        <h5 className="tags available">In Stock</h5>
        <h4>
          <small className="text-muted">
            {discount > 0 && <strike>₹{price}</strike>}
          </small>{" "}
          &nbsp;₹{price - discount}
        </h4>
      </div>
      <div className="position-relative">
        <div className="button-increment input-group mb-3">
          <span
            id="basic-addon1"
            className={
              quantity === 1 ? "input-group-text disabled" : "input-group-text"
            }
          >
            {" "}
            <MdRemove onClick={handleQuantityDecrement} />{" "}
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="1"
            aria-label="1"
            aria-describedby="basic-addon1"
            value={quantity}
            onChange={handleQuantity}
          />
          <span className="input-group-text" id="basic-addon1">
            {" "}
            <MdAdd onClick={handleQuantityIncrement} />{" "}
          </span>
        </div>
        <Link
          className="remove-btn"
          onClick={() => {
            handleRemoveCartProduct({ productId: productId });
          }}
        >
          Remove
        </Link>
      </div>
    </div>
  );
};

export default CheckoutItem;
