import React from "react";
import { Link } from "react-router-dom";

const CategorySection = () => {
  const categories = [
    { name: "Shopping", image: "../img/shopping.svg" },
    { name: "Entertainment", image: "../img/entertainment.svg" },
    { name: "Home & Grocery", image: "../img/home-groccery.svg" },
    { name: "Accessories & Jewellery", image: "../img/shopping.svg" },
    { name: "Beauty & Wellness", image: "../img/shopping.svg" },
    { name: "Dining", image: "../img/shopping.svg" },
    { name: "Shopping", image: "../img/shopping.svg" },
    { name: "Entertainment", image: "../img/entertainment.svg" },
    { name: "Home & Grocery", image: "../img/home-groccery.svg" },
    { name: "Accessories & Jewellery", image: "../img/shopping.svg" },
    { name: "Beauty & Wellness", image: "../img/shopping.svg" },
    { name: "Dining", image: "../img/shopping.svg" },
  ];
  return (
    <section className="scroll-hidden">
      <div className="container horizontal-scroll">
        {categories &&
          categories.map((category, i) => (
            <Link
              className="home-badge"
              key={i}
              to={"/giftcards?category=" + category.name}
            >
              {" "}
              <img src={category.image} alt="category"></img> {category.name}
            </Link>
          ))}
      </div>
    </section>
  );
};

export default CategorySection;
