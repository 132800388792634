import React from "react";
import { Link } from "react-router-dom";

import "./Footer.css";

export default function Footer() {
  return (
    // <div className="container">
    //   <div className="row">
    //     <div className="col-xs-12 col-sm-12 col-md-12">
    <footer id="footer" className="footer color-bg">
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
              <div className="module-heading">
                <h4 className="module-title">Help and Support</h4>
              </div>
              {/* <!-- /.module-heading --> */}

              <div className="module-body">
                <ul className="list-unstyled">
                  <li className="first">
                    <Link to="/termsandconditions" title="Contact us">
                      Terms & Conditions
                    </Link>
                  </li>
                  <li>
                    <Link to="/help">FAQs</Link>
                  </li>
                  <li>
                    <Link to="/howitworks">How it Works</Link>
                  </li>
                  <li>
                    <Link to="/privacypolicy">Privacy Policy</Link>
                  </li>
                  <li className="last">
                    <Link to="/refundpolicy">Return Policy</Link>
                  </li>
                </ul>
              </div>
              {/* <!-- /.module-body --> */}
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
              <div className="module-heading">
                <h4 className="module-title">Customer Service</h4>
              </div>
              {/* <!-- /.module-heading --> */}

              <div className="module-body">
                <ul className="list-unstyled">
                  <li className="first">
                    <Link to="/profiledetails" title="My Account">
                      My Account
                    </Link>
                  </li>
                  <li>
                    <Link to="/orders" title="Order History">
                      Order History
                    </Link>
                  </li>
                  <li>
                    <Link to="/aboutus" title="About Us">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" title="Popular Searches">
                      Contact Us
                    </Link>
                  </li>
                  <li className="last">
                    <Link to="/help" title="Help Center">
                      Help Center
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- /.module-body --> */}
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
              <div className="module-heading">
                <h4 className="module-title">DOWNLOAD OUR APP</h4>
              </div>
              <div className="footer-app">
                <img src={require("./zooppay-app-footer.png")} alt="sevenpay" />
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
              <div className="footer-app-buttons">
                <a
                  href="https://play.google.com/store/apps/details?id=com.sevenpay&pcampaignid=web_share"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require("./android-download.png")} alt="Android" />
                </a>
                <img
                  src={require("./app-store-download.png")}
                  alt="App Store"
                />
              </div>
            </div>
          </div>
          {/* <!-- /.col --> */}
        </div>
      </div>

      {/* <div className="copyright-bar">
        <div className="container">
          <div className="col-xs-12 col-sm-12 no-padding social">
            <ul className="link">
              <li className="fb">
                <Link target="_blank" rel="nofollow" to="/" title="Facebook"></Link>
              </li>
              <li className="tw">
                <Link target="_blank" rel="nofollow" to="/" title="Twitter"></Link>
              </li>
              <li className="linkedin">
                <Link target="_blank" rel="nofollow" to="/" title="Linkedin"></Link>
              </li>
              <li className="whatsapp">
                <Link target="_blank" rel="nofollow" to="/" title="Whatsapp"></Link>
              </li>
              <li className="youtube">
                <Link target="_blank" rel="nofollow" to="/" title="Youtube"></Link>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
    </footer>
    //     </div>
    //   </div>
    // </div>
  );
}
