import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Alert, Button, Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { MdArrowBack, MdGppGood, MdKeyboardArrowRight } from "react-icons/md";
import APIService from "../../services/api.service";
import { getCartProducts } from "../../store/cartSlice";
import CartProduct from "./CartProduct";
import { Link } from "react-router-dom";
import CustomNavBar from "../CustomNavBar";
import { orderActions } from "../../store/orderSlice";
import CartEmpty from "./CartEmpty";

function Cart() {
  const dispatch = useDispatch();
  const { products, total, finalTotal, totalDiscount } = useSelector(
    (state) => state.cart
  );
  // console.log("products in cart page", products);

  const handleRemoveCartProduct = (data) => {
    // console.log("removing.....");
    APIService.sendRequest("/api/v1/cart", data, "DELETE").then((response) => {
      // console.log("removed", response);
      if (response?.success) dispatch(getCartProducts());
    });
  };

  const handleUpdateCartProduct = (data) => {
    // console.log("updating....");
    APIService.sendRequest("/api/v1/cart", data, "PATCH").then((response) => {
      // console.log("updated", response);
      if (response?.success) dispatch(getCartProducts());
    });
  };

  useEffect(() => {
    dispatch(getCartProducts());
  }, []);

  useEffect(() => {
    dispatch(
      orderActions.setOrder({
        order: { gateway: {}, orderId: "", products: [] },
      })
    );
  }, []);

  return (
    <>
      {/* <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
          <div>
            <a href="/" className="back-icon">
              <MdArrowBack />
            </a>
            <h5 className="text-white m-3 float-start">Cart</h5>
          </div>
        </Container>
      </Navbar> */}
      <CustomNavBar title="Cart" />

      {products && products?.length > 0 && (
        <section>
          <div className="gift-details cart-row">
            <Container>
              <Row>
                <Col xs={12} md={8} className="left-cart">
                  {products.map((product, i) => (
                    <CartProduct
                      key={i}
                      productId={product.productId}
                      name={product.product.name}
                      image={
                        product?.product?.images?.custom ||
                        product?.product?.images?.mobile
                      }
                      discount={product?.discount}
                      price={product.price}
                      discountPrice={product.subtotal}
                      quantity={product.quantity}
                      handleRemoveCartProduct={handleRemoveCartProduct}
                      handleUpdateCartProduct={handleUpdateCartProduct}
                    />
                  ))}

                  <div className="checkout-bottom">
                    <h4 className="total-mobile mobile-view">
                      Total Amount <span>₹{total}</span>
                    </h4>
                    {products?.length > 0 && (
                      <Link to="/checkout">
                        <Button variant="primary" size="lg">
                          Continue to Checkout <MdKeyboardArrowRight />
                        </Button>
                      </Link>
                    )}
                  </div>
                </Col>
                <Col xs={12} md={4}>
                  <div className="price-details">
                    <label>Price Details</label>
                    <h4>
                      Price ({products?.length} items){" "}
                      <span>₹{finalTotal}</span>
                    </h4>
                    <h4>
                      Discount <span>₹{totalDiscount}</span>
                    </h4>
                    <h4 className="total">
                      Total Amount <span>₹{total}</span>
                    </h4>
                    {totalDiscount > 0 && (
                      <Alert variant="success">
                        Hurrey!
                        <p>You got ₹{totalDiscount} OFF on cards</p>
                      </Alert>
                    )}
                  </div>
                  <div className="safe">
                    <MdGppGood /> Safe and Secure Payments.
                  </div>
                </Col>
              </Row>
              <div className="details-card"></div>
            </Container>
          </div>
        </section>
      )}
      {!products || (products?.length <= 0 && <CartEmpty />)}
    </>
  );
}

export default Cart;
