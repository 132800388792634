import React, { useEffect } from "react";

const FileDownload = () => {
  useEffect(() => {
    // Path to your file located in the public folder
    const fileUrl = `${process.env.PUBLIC_URL}/9A0FFD85615BC423E21436311D698924.txt`; // example file path
    const fileName = "9A0FFD85615BC423E21436311D698924.txt"; // name for the downloaded file

    // Create an invisible link element
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName;

    // Append link to the body and trigger click to auto-download the file
    document.body.appendChild(link);
    link.click();

    // Clean up: remove the link from the DOM after download
    document.body.removeChild(link);
  }, []);

  return <div>Downloading the file...</div>;
};

export default FileDownload;
