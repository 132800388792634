import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import { MdArrowForwardIos } from "react-icons/md";
import { Link } from "react-router-dom";
import CustomNavBar from "./CustomNavBar";

function About() {
  return (
    <>
      <CustomNavBar title={"About Sevenpay"} />
      <section className="profile-summary-wrapper">
        <div className="desktop-wrapper">
          <Container className="cart-wrapper">
            <h4>Contact Us</h4>
            <ListGroup>
              <ListGroup.Item>
                <p>For Support/Enquiries</p>
                <h6>+91 98278 32784</h6>
              </ListGroup.Item>
              <ListGroup.Item>
                <p>Email</p>
                <h6>support@bankify.money</h6>
              </ListGroup.Item>
              <ListGroup.Item>
                <p>Address</p>

                <h6>7X Fintech Pvt Limited, DCB 620, DLF</h6>
                <h6>CYBERCITY, Infocity, Patia,</h6>
                <h6>Bhubaneswar, Odisha - 751024</h6>
              </ListGroup.Item>
            </ListGroup>
            <ListGroup className="mt-5">
              <Link to="/howitworks">
                <ListGroup.Item>
                  <h6>How its works?</h6>
                  <MdArrowForwardIos />
                </ListGroup.Item>
              </Link>
            </ListGroup>
            <ListGroup>
              <Link to="/privacy">
                <ListGroup.Item>
                  <h6>Privacy Policy</h6>
                  <MdArrowForwardIos />
                </ListGroup.Item>
              </Link>
            </ListGroup>

            <ListGroup>
              <Link to="/refund">
                <ListGroup.Item>
                  <h6>Return Policy</h6>
                  <MdArrowForwardIos />
                </ListGroup.Item>
              </Link>
            </ListGroup>
            <p className="small mt-5 muted copywrites">
              Copyright © 2024 Sevenpay. Ltd. All rights reserved.
            </p>
          </Container>
        </div>
      </section>
    </>
  );
}

export default About;
