import React, { useEffect, useState } from "react";

const PayuPaymentGateway = (props) => {
  const gateway = props.gateway;
  // console.log("gateway......", gateway);

  useEffect(() => {
    if (
      gateway.key &&
      gateway.txnid &&
      gateway.amount &&
      gateway.productinfo &&
      gateway.firstname &&
      gateway.email &&
      gateway.phone &&
      gateway.surl &&
      gateway.furl &&
      gateway.hash &&
      gateway.action
    )
      document.forms["payment_post"].submit();
  }, [gateway]);

  return (
    <form
      name="payment_post"
      id="payment_post"
      action={gateway.action}
      method="post"
    >
      <input hidden type="text" name="key" defaultValue={gateway.key} />
      <input hidden type="text" name="txnid" defaultValue={gateway.txnid} />
      <input hidden type="text" name="amount" defaultValue={gateway.amount} />
      <input
        hidden
        type="text"
        name="productinfo"
        defaultValue={gateway.productinfo}
      />
      <input
        hidden
        type="text"
        name="firstname"
        defaultValue={gateway.firstname}
      />
      <input hidden type="text" name="email" defaultValue={gateway.email} />
      <input hidden type="text" name="phone" defaultValue={gateway.phone} />
      <input hidden type="text" name="surl" defaultValue={gateway.surl} />
      <input hidden type="text" name="furl" defaultValue={gateway.furl} />
      <input hidden type="text" name="curl" defaultValue={gateway.curl} />
      <input hidden type="text" name="hash" defaultValue={gateway.hash} />
    </form>
  );
};

export default PayuPaymentGateway;
