import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { Button, Col, Form, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { MdArrowBack } from "react-icons/md";

import { loginUser, userActions } from "../../store/userSlice";
import { setCookieItem } from "../secureroute/cookies";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isLoggedIn,
    token,
    user,
    error,
    errorMessage,
    isLoginError,
    loginErrorMessage,
  } = useSelector((state) => state.user);

  // console.log(
  //   isLoggedIn,
  //   token,
  //   user,
  //   error,
  //   errorMessage,
  //   isLoginError,
  //   loginErrorMessage
  // );

  const [auth, setAuth] = useState({
    username: "",
    password: "",
  });

  function handleInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    setAuth({
      ...auth,
      [name]: value,
    });
  }

  function handleSubmit(e) {
    dispatch(userActions.setLoginError({ success: true, errorMessage: "" }));
    e.preventDefault();
    dispatch(loginUser(auth));
  }

  useEffect(() => {
    if (isLoggedIn) {
      setCookieItem("token", token);
      setCookieItem("user", JSON.stringify(user));
      navigate("/");
    }
    // if (error) toastSlideError(errorMessage);
    // if (error) console.log(errorMessage);
  }, [
    isLoggedIn,
    navigate,
    token,
    user,
    error,
    errorMessage,
    isLoginError,
    loginErrorMessage,
  ]);

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container>
          <div>
            <Link to="/" className="back-icon">
              <MdArrowBack />
            </Link>
            <h5 className="text-white m-3 float-start">Signin</h5>
          </div>
        </Container>
      </Navbar>
      <section>
        <Container className="login-wrapper">
          <Row>
            <Col className="left">
              <h1>Login</h1>
              <h4>Get access to your Orders, Wishlist and Recommendations</h4>
              <img src="../img/banner.svg" alt="banner" />
            </Col>
            <Col className="right">
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    defaultValue={auth.username}
                    onChange={handleInput}
                    name="username"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    defaultValue={auth.password}
                    onChange={handleInput}
                    name="password"
                    isInvalid={isLoginError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {loginErrorMessage}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>
                    <Link to={"/forgotpassword"}>Forget Password?</Link>
                  </Form.Label>
                </Form.Group>
                <Button
                  variant="primary"
                  size="lg"
                  className="w-100 mt-3"
                  type="submit"
                >
                  Sign In
                </Button>
              </Form>
              <div className="text-center mt-3">
                <p>
                  Dont have account? <Link to={"/register"}>Signup</Link>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Login;
