import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { MdArrowBack, MdCheckCircle } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import APIService from "../../services/api.service";
import Loader from "../loader/Loader";

// import { userActions } from "../../store/userSlice";

const EmailVerified = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const params = useParams();

  const handleSubmit = () => {
    APIService.sendRequest(
      "/auth/verify/email/" + params.userId + "/" + params.hash
    ).then((response) => {
      // console.log(response);
      if (!response) {
        setIsError(true);
        setErrorMessage("something went wrong");
        setLoading(false);
        return;
      }
      if (!response.success) {
        setIsError(true);
        setErrorMessage(response.errorMessage);
        setLoading(false);
        return;
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container>
          <div>
            <Link to="/profiledetails" className="back-icon">
              <MdArrowBack />
            </Link>
            <h5 className="text-white m-3 float-start">Email Verification</h5>
          </div>
        </Container>
      </Navbar>

      {isLoading && <Loader />}
      {isError && <p className="text-danger">{errorMessage}</p>}

      {!isLoading && !isError && (
        <section>
          <div className="desktop-wrapper mt-5">
            <Container className="cart-wrapper">
              <div className="details-card text-center">
                <img
                  src="../../../img/success.svg"
                  className="success-img"
                  alt="successimg"
                />
                <h3 className="green-text">Email Verified!</h3>
                <p>Your email has been verified successfully.</p>
                <Button
                  variant="primary"
                  size="lg"
                  className="mt-3"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Home
                </Button>
              </div>
            </Container>
          </div>
        </section>
        // <section>
        //   <div className="gift-details cart-ro pb-24 pt-24">
        //     <section>
        //       <div className="desktop-wrapper">
        //         <Container className="cart-wrapper">
        //           <div className="status-bar">
        //             <MdCheckCircle />
        //             <br />
        //             <h4>Email verified successfully</h4>
        //           </div>
        //         </Container>
        //         <Link to={"/"}>Go Home</Link>
        //       </div>
        //     </section>
        //   </div>
        // </section>
      )}
    </>
  );
};

export default EmailVerified;
