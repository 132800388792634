import { createSlice } from "@reduxjs/toolkit";
import APIService from "../services/api.service";

const initialState = {
  products: [],
  total: 0,
  success: false,
  errorMessage: "",
};
const giftCardSlice = createSlice({
  name: "giftCard",
  initialState,
  reducers: {
    setProducts(state, action) {
      // console.log("action.payload", action.payload?.products);
      state.products = action.payload?.products;
      state.total = state.products.length;
    },
    setError(state, action) {
      state.isLoggedIn = false;
      state.success = true;
      state.errorMessage = action.payload?.errorMessage;
    },
  },
});

export const getProducts = (brand) => {
  return async (dispatch) => {
    try {
      var url = "/api/v1/products";
      if (brand && brand?.length > 2) {
        url = url + "?brand=" + brand;
      }

      const response = await APIService.sendRequest(url);
      // console.log(response);
      if (response?.success) dispatch(giftCardActions.setProducts(response));
      // if (!response?.success) dispatch(giftCardActions.setError(response));
    } catch (err) {
      // console.log(err);
    }
  };
};

export const getCategories = () => {
  return async (dispatch) => {
    try {
      const response = await APIService.sendRequest("/api/v1/products");
      // console.log(response);
      if (response?.success) dispatch(giftCardActions.setProducts(response));
      // if (!response?.success) dispatch(giftCardActions.setError(response));
    } catch (err) {
      // console.log(err);
    }
  };
};

export const giftCardActions = giftCardSlice.actions;
export default giftCardSlice.reducer;
