import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import APIService from "../../services/api.service";
import { ToastContainer, toast } from "react-toastify";

const AddressDeleteModel = (props) => {
  const onAddressDeleted = props.onAddressDeleted;
  const addressId = props.addressId;
  // console.log("addressId", addressId);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("addressId", props?.addressId, props?.name);
    APIService.sendRequest(
      "/api/v1/address/",
      {
        addressId: addressId,
      },
      "DELETE"
    ).then((response) => {
      // console.log(response);
      if (response?.success) {
        onAddressDeleted();
        handleClose();
      } else {
        // error message
        showNotifications(response?.errorMessage);
      }
    });
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const showNotifications = (msg) => {
    if (msg?.length > 0)
      toast(msg, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // className: "toaster_custom_color",
        // bodyClassName: "toaster_custom_body_color",
      });
  };

  return (
    <>
      <Button variant="danger" onClick={handleShow}>
        Remove
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Address Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-danger">
            Are you sure you want to delete this?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleSubmit}>
            Delete
          </Button>
        </Modal.Footer>
        <ToastContainer />
      </Modal>
    </>
  );
};

export default AddressDeleteModel;
