import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link, useParams } from "react-router-dom";
import APIService from "../../services/api.service";
import CustomNavBar from "../CustomNavBar";
import OrderGiftCardDetails from "./OrderGiftCardDetails";

function OrderDetails() {
  // TODO: handle card expiry
  const params = useParams();
  const [order, setOrder] = useState({});

  useEffect(() => {
    if (params?.orderId)
      APIService.sendRequest("/api/v1/order/" + params?.orderId).then(
        (response) => {
          // console.log(response);
          if (response.success) {
            // console.log("dfsdjnfsdf");
            setOrder(response.order);
          }
        }
      );
  }, [params?.orderId]);

  return (
    <>
      <CustomNavBar title={"Orders Details"} />
      <section>
        <div className="desktop-wrapper">
          <Container className="cart-wrapper">
            <div className="order-id d-flex">
              <div>
                <p className="m-0">Order placed on: </p>
                <p>{order?.createdAt?.slice(0, 10)}</p>
              </div>
              <div>
                <p className="m-0">Order ID: </p>
                <p>#{order?.orderId}</p>
              </div>
              {/* <p>Order ID: #{order?.orderId}</p> */}
            </div>

            <div className="listing-card d-flex">
              {order?.products?.length > 0 && (
                <Card.Img
                  className="gift-img"
                  variant="top"
                  src={
                    order?.products[0]?.product?.images?.custom ||
                    order?.products[0]?.product?.images?.mobile
                  }
                  alt="orderimage"
                />
              )}
              <Card.Body>
                <Card.Title>
                  {order?.products?.length > 0 &&
                    order?.products[0]?.product?.name}
                </Card.Title>
                <Card.Text>
                  ₹{order?.products?.length > 0 && order?.products[0]?.price}{" "}
                  <br />
                  <small>
                    {order?.products?.length > 0 &&
                      order?.products[0]?.product?.issuerData?.expiry}{" "}
                  </small>
                </Card.Text>
              </Card.Body>
            </div>
            <div className="order-status">
              <small>Order Status </small>
              <p className="success">{order?.statusLabel}</p>
            </div>
            {order?.s3Url && (
              <div className="order-status">
                <small>Invoice </small>
                <p className="success">
                  <a href={order?.s3Url} download target="__blank">
                    Download
                  </a>
                </p>
              </div>
            )}
            <div className="order-status">
              {/* <small>Card Number </small> */}
              <OrderGiftCardDetails
                orderItem={order}
                // product={order?.products[0]?.product}
              />
              {/* <p>34567890000013344</p> */}
            </div>

            <div className="order-help d-flex">
              <p>
                <Link to="/contactus">Need Help?</Link>
              </p>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
}

export default OrderDetails;
