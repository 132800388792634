import { createSlice } from "@reduxjs/toolkit";
import APIService from "../services/api.service";

const initialState = {
  products: [],
  addresses: [],
  total: 0,
  finalTotal: 0,
  success: false,
  errorMessage: "",
  totalDiscount: 0,
};
const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCartProducts(state, action) {
      // console.log("action.payload in cart", action.payload);
      state.products = action.payload?.cart;
      state.total = action.payload?.total;
      state.finalTotal = action.payload?.finalTotal;
      state.totalDiscount = action.payload?.cart
        ?.map((o) => o.discount * o.quantity)
        ?.reduce((a, c) => a + c, 0);
    },
    setAddresses(state, action) {
      // console.log("action.payload in address", action.payload);
      state.addresses = action.payload?.addresses;
    },

    setError(state, action) {
      state.isLoggedIn = false;
      state.success = true;
      state.errorMessage = action.payload?.errorMessage;
    },
  },
});

export const getCartProducts = () => {
  return async (dispatch) => {
    try {
      const response = await APIService.sendRequest("/api/v1/cart");
      // console.log(response);
      if (response?.success) {
        // console.log(response.success);
        dispatch(cartActions.setCartProducts(response));
      }
      // if (!response?.success) dispatch(cartActions.setError(response));
    } catch (err) {
      // console.log(err);
    }
  };
};

export const getAddresses = () => {
  return async (dispatch) => {
    try {
      const response = await APIService.sendRequest("/api/v1/address");
      // console.log(response);
      if (response?.success) {
        // console.log(response.success);
        dispatch(cartActions.setAddresses(response));
      }
      // if (!response?.success) dispatch(cartActions.setError(response));
    } catch (err) {
      // console.log(err);
    }
  };
};

export const cartActions = cartSlice.actions;
export default cartSlice.reducer;
