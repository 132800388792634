import React, { useState } from "react";
import { Button, ButtonGroup, ButtonToolbar, Form } from "react-bootstrap";
import APIService from "../../services/api.service";
import CustomNavBar from "../CustomNavBar";

function AddressEdit(props) {
  // var firstNameRef = useRef(props.firstName);
  // var lastNameRef = useRef(props.lastName);
  // var phoneNumberRef = useRef(props.phoneNumber);
  // var pinCodeRef = useRef(props.pinCode);
  // var address1Ref = useRef(props.address1);
  // var address2Ref = useRef(props.address2);
  // var landMarkRef = useRef(props.landMark);
  // var cityRef = useRef(props.city);
  // var stateRef = useRef(props.state);
  // var countryRef = useRef(props.country);

  const [address, setAddress] = useState({
    addressId: props.addressId,
    firstName: props.firstName,
    lastName: props.lastName,
    addressType: props.addressType,
    address1: props.address1,
    address2: props.address2,
    landMark: props.landMark,
    city: props.city,
    state: props.state,
    country: props.country,
    pinCode: props.pinCode,
    phoneCountryCode: props.phoneCountryCode,
    phoneNumber: props.phoneNumber,
    isDefault: props.isDefault,
  });

  function handleInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    // console.log(name, value);
    setAddress({
      ...address,
      [name]: value,
    });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    APIService("/", address, "PATCH");
  };

  return (
    <>
      <CustomNavBar title={"Edit Address"} />

      <section className="profile-details-wrapper">
        <h4>Address Information</h4>

        <Form>
          <Form.Group className="mb-3" controlId="formFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="First name"
              name="firstName"
              value={address.firstName}
              onChange={handleInput}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Last name"
              name="lastName"
              value={address.lastName}
              onChange={handleInput}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formPhone">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Phone Number"
              name="phoneNumber"
              value={address.phoneNumber}
              onChange={handleInput}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Address </Form.Label>
            <Form.Control
              type="text"
              placeholder="Address line 1"
              name="address1"
              value={address.address1}
              onChange={handleInput}
            />
            <Form.Control
              className="mt-3"
              placeholder="Address line 2"
              type="text"
              name="address2"
              value={address.address2}
              onChange={handleInput}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>LandMark </Form.Label>
            <Form.Control
              type="text"
              placeholder="Landmark"
              name="landMark"
              value={address.landMark}
              onChange={handleInput}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Pin Code </Form.Label>
            <Form.Control
              type="text"
              placeholder="Pincode"
              name="pinCode"
              value={address.pinCode}
              onChange={handleInput}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>City </Form.Label>
            <Form.Control
              type="text"
              placeholder="City"
              name="city"
              value={address.city}
              onChange={handleInput}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>State </Form.Label>
            <Form.Control
              type="text"
              placeholder="State"
              name="state"
              value={address.state}
              onChange={handleInput}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Country </Form.Label>
            <Form.Control
              type="text"
              placeholder="Country"
              name="country"
              value={address.country}
              onChange={handleInput}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Address Type </Form.Label>{" "}
            <Form.Check
              inline
              label="Home"
              name="addressType"
              value="Home"
              type="radio"
              id={`inline-radio-1`}
              onChange={handleInput}
              checked={address.addressType === "Home" ? true : false}
            />
            <Form.Check
              inline
              label="Office"
              name="addressType"
              value="Office"
              type="radio"
              id={`inline-radio-1`}
              onChange={handleInput}
              checked={address.addressType === "Office" ? true : false}
            />
            <Form.Check
              inline
              label="Other"
              name="addressType"
              value="Other"
              type="radio"
              id={`inline-radio-1`}
              onChange={handleInput}
              checked={address.addressType === "Other" ? true : false}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Use this address as Default </Form.Label>{" "}
            <Form.Check
              type="checkbox"
              id="isDefault"
              name="isDefault"
              onChange={handleInput}
              checked={address.isDefault}
            />
          </Form.Group>
        </Form>
      </section>

      <div className="bottom-nav position-fixed">
        <ButtonToolbar aria-label="Toolbar with button groups">
          <ButtonGroup size="lg" className="me-2" aria-label="First group">
            <Button variant="secondary">Cancel</Button>
          </ButtonGroup>
          <ButtonGroup size="lg" className="me-2" aria-label="Second group">
            <Button variant="primary" type="submit">
              Update
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </div>
    </>
  );
}

export default AddressEdit;
