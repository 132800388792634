import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import Container from "react-bootstrap/Container";

import { Button, Form } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { MdArrowBack } from "react-icons/md";

import { sendForgotPasswordEmail } from "../../store/userSlice";

function ForgetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sentForgotPasswordEmail } = useSelector((state) => state.user);

  const [email, setEmail] = useState("");
  function handleInput(e) {
    const value = e.target.value;
    // console.log(value);
    setEmail(value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(sendForgotPasswordEmail(email));
  }

  useEffect(() => {
    if (sentForgotPasswordEmail) navigate("/forgot-password/success");
  }, [sentForgotPasswordEmail, navigate]);

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container>
          <div>
            <Link to="/" className="back-icon">
              <MdArrowBack />
            </Link>
            <h5 className="text-white m-3 float-start">Forget Password</h5>
          </div>
        </Container>
      </Navbar>
      <section>
        <div className="details-card p-4">
          <p>
            Password reset link will send to your email address, you can set
            your new password after fill the form
          </p>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                defaultValue={email}
                onChange={handleInput}
                name="email"
              />
            </Form.Group>
            <Button
              variant="primary"
              size="lg"
              className="w-100 mt-3"
              type="submit"
            >
              Reset Password
            </Button>
          </Form>
        </div>
      </section>
    </>
  );
}

export default ForgetPassword;
