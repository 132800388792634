import Cookies from "js-cookie";

Cookies.withAttributes(getCookieDefaultParams());

function getCookieDefaultParams() {
  if (window.location.origin.includes("http://localhost")) return { path: "/" };
  else {
    return { path: "/", domain: ".sevenpay.in" };
  }
}

function getCookieItem(key) {
  return Cookies.get(key);
}

function setCookieItem(key, value) {
  return Cookies.set(key, value, getCookieDefaultParams());
}

function removeCookieItem(key) {
  return Cookies.remove(key, getCookieDefaultParams());
}

function isValidateContinueUrl(url) {
  if (url.includes("sevenpay.in")) return true;
  else return false;
}

export default Cookies;
export {
  getCookieDefaultParams,
  getCookieItem,
  setCookieItem,
  removeCookieItem,
  isValidateContinueUrl,
};
