import React from "react";

const PhonepePaymentGateway = (props) => {
  console.log("PhonepePaymentGateway", props);
  React.useEffect(() => {
    window.location.replace(props?.gateway?.link);
  }, []);
};

export default PhonepePaymentGateway;
