import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { Form } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { MdSearch } from "react-icons/md";
import Drawer from "./Drawer";
import APIService from "../../services/api.service";
import SearchList from "./SearchList";
import "./Search.css";

const Search = () => {
  const [products, setProducts] = useState([]);
  const [offers, setOffers] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const fetchProducts = async () => {
    APIService.sendRequest("/api/v1/products")
      .then(async (response) => {
        // console.log(response);
        setProducts(response.products);
        setOffers(response.offers);
      })
      .catch((error) => {
        // console.log("error");
        // console.log(error);
      });
  };

  // useEffect(() => {
  //   fetchProducts();
  // }, []);

  const handleSearchKey = (e) => {
    // console.log(e.target.value, e.target.value === "");
    if (products && products.length <= 0) fetchProducts();
    setSearchKey(e.target.value);

    var fp;
    if (products) {
      fp = products.filter((product) => {
        return product.name.toLowerCase().includes(searchKey.toLowerCase());
      });
      setFilteredProducts(fp);
    }

    // console.log(filteredProducts);
    // console.log(fetchProducts());
    if (e.target.value === "") {
      setShowSearchResult(false);
    } else if (fp?.length <= 0) {
      setShowSearchResult(false);
    } else {
      setShowSearchResult(true);
    }
  };

  function searchList() {
    if (showSearchResult) {
      return (
        // <Scroll>
        <SearchList filteredProducts={filteredProducts.slice(0, 6)} />
        // </Scroll>
      );
    }
  }

  return (
    <Nav className="me-auto w-100">
      <Form className="d-flex position-relative search-form">
        <Form.Control
          type="search"
          placeholder="Search Giftcard"
          className="home-search"
          aria-label="Search"
          value={searchKey}
          onChange={handleSearchKey}
        />
        <MdSearch className="search-icon" />
      </Form>
      <div
        className="searchResult"
        style={{ visibility: showSearchResult ? "visible" : "hidden" }}
      >
        {searchList()}
      </div>
    </Nav>
  );
};

export default Search;
