import Container from "react-bootstrap/Container";

import { Button, Col, Row } from "react-bootstrap";

function Page404() {
  return (
    <>
      <section>
        <Container className="mt-5">
          <Row>
            <Col className="text-center mt-5" xs={12}>
              <img src="../img/404.svg" className="error-img" />
            </Col>
            <Col xs={12} className="text-center">
              <Button variant="primary" size="lg" className="mt-3" href="/">
                Back to Home
              </Button>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Page404;
