import React from "react";

const GiftCardOffer = (props) => {
  // TODO: handle product already added to cart issue
  const offer = props.offer;
  if (offer) {
    if (
      offer.minOrderPrice &&
      offer.minOrderPrice !== 0 &&
      offer.minOrderQuantity &&
      offer.minOrderQuantity !== 0
    ) {
      return (
        <>
          <p className="text">
            Discount: {offer.discount}% off on order over ₹{offer.minOrderPrice}
          </p>

          <p className="text">
            Discount: {offer.discount}% off on order over
            {offer.minOrderQuantity} giftcard(s)
          </p>

          <p className="text">Upto: ₹{offer.maxDiscount} off</p>
          <p className="text">
            Hurry up!!. Offer only for first: {offer.offerQuantity} customers
          </p>
        </>
      );
    } else if (offer.minOrderPrice && offer.minOrderPrice !== 0) {
      return (
        <>
          <p className="text">
            Discount: {offer.discount}% off on order over ₹{offer.minOrderPrice}
          </p>
          <p className="text">Upto: ₹{offer.maxDiscount} off</p>
          <p className="text">
            Hurry up!!. Offer only for first: {offer.offerQuantity} customers
          </p>
        </>
      );
    } else if (offer.minOrderQuantity && offer.minOrderQuantity !== 0) {
      return (
        <>
          <p className="text">
            Discount: {offer.discount}% off on order over
            {offer.minOrderQuantity} giftcard(s)
          </p>

          <p className="text">Upto: ₹{offer.maxDiscount} off</p>
          <p className="text">
            Hurry up!!. Offer only for first: {offer.offerQuantity} customers
          </p>
        </>
      );
    }
    return (
      <>
        <p className="text">Discount: {offer.discount}% off</p>
        <p className="text">Upto: ₹{offer.maxDiscount} off</p>
        <p className="text">
          Hurry up!!. Offer only for first: {offer.offerQuantity} customers
        </p>
      </>
    );
  }
  return <div className="something">No offer</div>;
};

export default GiftCardOffer;

{
  /* <p>
  <b>Discount: </b>5% off on order over ₹500 <br />
  <b>Discount:</b> 5% off on order over1 giftcard(s)
  <br />
  <b>Upto:</b> ₹500 off
  <br />
  Hurry up!!. Offer only for first: customers
  <br />
</p>; */
}
