import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCookieItem } from "./cookies";

import { userActions } from "./../../store/userSlice";

function ProtectedRoute({ children }) {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("isLoggedIn", isLoggedIn, typeof isLoggedIn);
    if (isLoggedIn) {
      // console.log("yes logged in");

      var cookieToken = getCookieItem("token");
      if (cookieToken) {
        // console.log("cookie found");
        dispatch(userActions.setUser({ user, token }));
        // navigate("/");
      }
    } else {
      // console.log("not logged in");
      // console.log(user, token);
      var cookieToken = getCookieItem("token");
      // console.log(cookieToken);
      if (cookieToken) {
        // console.log("cookie found");
        dispatch(userActions.setUser({ user, token }));
        // navigate("/");
      }
      // else {
      //   navigate("/login");
      // }
    }
  }, [isLoggedIn, dispatch, token, user]);

  return (
    <>
      {isLoggedIn && children}
      {!isLoggedIn && <ExternalRedirect to={"/login"} />}
    </>
  );
}

const ExternalRedirect = (props) => {
  window.location.href = window.location.origin + "/login";
  return null;

  // console.log("ExternalRedirect---------", props);
  // if (isLocalDevelopment()) {
  //   //development
  //   console.log("development--------------");
  //   window.location.href = window.location.origin + "/login";
  //   return null;
  // } else {
  //   let continueURL =
  //     props.to + "?continue=" + encodeURIComponent(window.location.href);
  //   window.location.replace(continueURL);
  //   return null;
  // }
};

export default ProtectedRoute;
