import React, { useEffect } from "react";
import CustomBottomNav from "../CustomBottomNav";

import { isBrowser } from "react-device-detect";

import BannerCarousel from "./BannerCarousel";
import CategorySection from "./CategorySection";
import Header from "./Header";
import MostRecommendedProducts from "./MostRecommendedProducts";
import OnlineShoppingProducts from "./OnlineShoppingProducts";
import PopularBrands from "./PopularBrands";
import TopBrands from "./TopBrands";
import AndroidPermissionRequestWidget from "./AndroidPermissionRequestWidget";
import Footer from "../footer/Footer";

function Home() {
  // console.log("home", isLoggedIn, token, user, error, errorMessage);
  var permissions = window?.cordova?.plugins?.permissions;

  function error() {
    console.warn("Camera permission is not turned on");
  }

  function success(status) {
    if (!status?.hasPermission) error();
    else {
      console.log("Camera permission is turned ");
    }
  }

  useEffect(() => {
    if (permissions)
      permissions?.requestPermission(permissions?.RECEIVE_SMS, success, error);
  }, []);
  return (
    <>
      <Header />
      {/* {!isBrowser && <AndroidPermissionRequestWidget />} */}
      <CategorySection />
      <BannerCarousel />
      <PopularBrands />
      <TopBrands />
      <MostRecommendedProducts />
      <OnlineShoppingProducts />
      <CustomBottomNav />
      {isBrowser && <Footer />}
    </>
  );
}

export default Home;
