import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import APIService from "../../services/api.service";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import AddressDeleteModel from "./AddressDeleteModel";

const AddressUpdateModel = (props) => {
  const [address, setAddress] = useState({
    addressId: props.addressId,
    firstName: props.firstName,
    lastName: props.lastName,
    addressType: props.addressType,
    address1: props.address1,
    address2: props.address2,
    landMark: props.landMark,
    city: props.city,
    state: props.state,
    country: props.country,
    pinCode: props.pinCode,
    phoneCountryCode: props.phoneCountryCode,
    phoneNumber: props.phoneNumber,
    isDefault: props.isDefault,
  });

  const onNewAddressAdded = props.onNewAddressAdded;

  const onAddressDeleted = () => {
    onNewAddressAdded();
    handleClose();
  };

  function handleInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    // console.log(name, defaultValue);
    setAddress({
      ...address,
      [name]: value,
    });
  }

  function handleCheckBoxInput(e) {
    const name = e.target.name;
    const value = e.target.checked;
    // console.log(name, defaultValue);
    setAddress({
      ...address,
      [name]: value,
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    APIService.sendRequest("/api/v1/address/", address, "PATCH").then(
      (response) => {
        // console.log(response);
        if (response?.success) {
          onNewAddressAdded();
          handleClose();
        } else {
          // error message
          showNotifications(response?.errorMessage);
        }
      }
    );
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const showNotifications = (msg) => {
    if (msg?.length > 0)
      toast(msg, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // className: "toaster_custom_color",
        // bodyClassName: "toaster_custom_body_color",
      });
  };

  return (
    <>
      {/* <Button variant="mb-1" size="md" onClick={handleShow}>
        + Add New Address
      </Button> */}
      <Link className="edit" onClick={handleShow}>
        Edit
      </Link>{" "}
      <Modal show={show} onHide={handleClose} className="add-address">
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="First name"
                name="firstName"
                defaultValue={address.firstName}
                onChange={handleInput}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last name"
                name="lastName"
                defaultValue={address.lastName}
                onChange={handleInput}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Phone Number"
                name="phoneNumber"
                defaultValue={address.phoneNumber}
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Address </Form.Label>
              <Form.Control
                type="text"
                placeholder="Address line 1"
                name="address1"
                defaultValue={address.address1}
                onChange={handleInput}
              />
              <Form.Control
                className="mt-3"
                placeholder="Address line 2"
                type="text"
                name="address2"
                defaultValue={address.address2}
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>LandMark </Form.Label>
              <Form.Control
                type="text"
                placeholder="Landmark"
                name="landMark"
                defaultValue={address.landMark}
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Pin Code </Form.Label>
              <Form.Control
                type="text"
                placeholder="Pincode"
                name="pinCode"
                defaultValue={address.pinCode}
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>City </Form.Label>
              <Form.Control
                type="text"
                placeholder="City"
                name="city"
                defaultValue={address.city}
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>State </Form.Label>
              <Form.Control
                type="text"
                placeholder="State"
                name="state"
                defaultValue={address.state}
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Country </Form.Label>
              <Form.Control
                type="text"
                placeholder="Country"
                name="country"
                defaultValue={address.country}
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Address Type </Form.Label>{" "}
              <Form.Check
                inline
                label="Home"
                name="addressType"
                defaultValue="Home"
                type="radio"
                id={`inline-radio-1`}
                onChange={handleInput}
                checked={address.addressType === "Home" ? true : false}
              />
              <Form.Check
                inline
                label="Office"
                name="addressType"
                defaultValue="Office"
                type="radio"
                id={`inline-radio-1`}
                onChange={handleInput}
                checked={address.addressType === "Office" ? true : false}
              />
              <Form.Check
                inline
                label="Other"
                name="addressType"
                defaultValue="Other"
                type="radio"
                id={`inline-radio-1`}
                onChange={handleInput}
                checked={address.addressType === "Other" ? true : false}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Use this address as Default </Form.Label>{" "}
              <Form.Check
                type="checkbox"
                id="isDefault"
                name="isDefault"
                onChange={handleCheckBoxInput}
                checked={address.isDefault}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <AddressDeleteModel
              addressId={props?.addressId}
              name={props?.firstName + "-" + props?.lastName}
              onAddressDeleted={onAddressDeleted}
            />
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Update
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddressUpdateModel;
