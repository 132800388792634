import React from "react";
import { Link, NavLink } from "react-router-dom";

const CustomBottomNav = () => {
  return (
    <div className="bottom-nav position-fixed mobile-view">
      <ul className="nav nav-pills nav-justified">
        <li className="nav-item">
          <NavLink
            to="/home"
            className={({ isActive, isPending }) => {
              // console.log("home", isActive);
              return isPending
                ? "pending"
                : isActive
                ? "nav-link active"
                : "nav-link";
            }}
          >
            <span className="material-symbols-outlined">home</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/giftcards"
            className={({ isActive, isPending }) => {
              // console.log("giftcards", isActive);
              return isPending
                ? "pending"
                : isActive
                ? "nav-link active"
                : "nav-link";
            }}
          >
            <span className="material-symbols-outlined">redeem</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            to="/profile"
            className={({ isActive, isPending }) => {
              // console.log("profile", isActive);
              return isPending
                ? "pending"
                : isActive
                ? "nav-link active"
                : "nav-link";
            }}
          >
            <span className="material-symbols-outlined">person</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default CustomBottomNav;
